import { Uuid } from '@/types';
import { performInternalApiCall } from '.';
import { IFolder, IFolderWithContent } from './item';

export type DraftFilter = 'ALL' | 'DRAFT_ONLY' | 'NO_DRAFT'

export interface IContentParam {
  folderId?: Uuid
  root?: boolean
  draft?: DraftFilter
}

export interface AdditionalMetadata {
  free_account?: boolean
}

export function content({ folderId, root, draft = 'NO_DRAFT' }: IContentParam) {
  const queryString = root ? 'root=true' : `folder_id=${folderId}`;

  return performInternalApiCall<IFolderWithContent & AdditionalMetadata>(`folder/r/content/?${queryString}&draft=${draft}`);
}

export function create(params: { parentFolderId: Uuid, name: string }) {
  return performInternalApiCall<IFolder>('folder/w/', {
    method: 'POST',
    payload: JSON.stringify(params),
  });
}
