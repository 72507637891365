
import Vue from 'vue';
import { viewerApps, VIEWER_APP_STATIC_URL } from '@/viewer_app';
import { ViewerAppConstructor } from '@/viewer_app/concrete';
import { ILoadableVolume, IUploadableVolume } from '@/api/item';
import { volumeDataFormatToFileFormat } from '@/helpers/volume.helper';
import { mapMutations } from 'vuex';
import { getFileFormatExtensions } from '@/api/volume';

interface IViewerAppWrapperData {
  viewerAppUrl: string
  viewerAppIframe: null | HTMLIFrameElement
  ready: boolean
}

/**
 * Please note that we cannot hold a direct reference to a Vue Store into the a component's data option.
 * This is because a store is a reactive Vue object and setting it as a data option property (which is reactive)
 * will make Vue try to make the store reactive again although it already is.
 * You would then run into strange behavior, infinite render loop which will make the application completely unusable.
 */

export default Vue.extend({
  props: {
    volume: {
      type: Object as () => ILoadableVolume,
      required: false,
      default: () => null,
    },
    tabItem: {
      type: Object,
      required: true,
    },
  },
  data: () : IViewerAppWrapperData => ({
    viewerAppUrl: VIEWER_APP_STATIC_URL,
    viewerAppIframe: null,
    ready: false,
  }),

  watch: {
    volume: {
      async handler(volume: ILoadableVolume | IUploadableVolume) {
        if (volume == null) {
          return;
        }

        // Try to open the volume once, and if it fails, register a watcher to retry when the viewer is ready
        if (!(await this.openVolume(volume))) {
          const unwatch = this.$watch(() => this.ready, (ready: boolean) => {
            if (ready) {
              this.openVolume(volume).then(() => {
                unwatch();
              });
            }
          });
        }
      },
      immediate: true,
    },
  },

  mounted() {
    this.viewerAppIframe = this.$refs.viewerAppIframe as HTMLIFrameElement;
    // Construct a ViewerApp instance when the iframe has finished loading
    this.viewerAppIframe.addEventListener('load', () => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const viewerApp = new ViewerAppConstructor(this.viewerAppIframe!);
      viewerApps.set(this.tabItem, viewerApp);

      // At this point the component is ready to forward calls to the viewer application
      this.ready = true;
      this.$emit('ready');
    });
  },

  methods: {
    ...mapMutations('tabs', {
      updateTabStatus: 'UPDATE_TAB_STATUS',
    }),
    async openVolume(volume: ILoadableVolume | IUploadableVolume) {
      const viewerApp = viewerApps.get(this.tabItem);
      if (!viewerApp) {
        return false;
      }

      this.updateTabStatus({ tabItem: this.tabItem, status: 'LOADING' });

      if ('fileUrls' in volume) {
        await viewerApp.openRemoteVolume(volume.fileUrls, volume.state);
      } else {
        const fileFormat = volumeDataFormatToFileFormat(volume.data_format);
        const extensions = getFileFormatExtensions(fileFormat);
        await viewerApp.openLocalVolume(volume.fileList, extensions);
      }
      this.updateTabStatus({ tabItem: this.tabItem, status: 'LOADED' });

      viewerApp.openDrawerPanel();

      return true;
    },
  },
});
