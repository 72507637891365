import { Uuid } from '@/types';
import { IViewerState } from '@/viewer_app';
import { performInternalApiCall } from '.';

export async function create(volumeId: Uuid, state: IViewerState) {
  return performInternalApiCall<never>('volume/state/w/', {
    method: 'POST',
    payload: JSON.stringify({
      volumeId,
      state,
    }),
  });
}
