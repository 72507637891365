import Vue from 'vue';
import App from './App.vue';
import router from './router';
import { createStore } from './store';
import vuetify from './plugins/vuetify';
import { STORE_BRIDGE } from './api';
import { Auth0Plugin, NoAuthPlugin } from './auth';
import { AUTH0_DOMAIN, AUTH0_CLIENT_ID } from '../auth_config';

Vue.config.productionTip = false;

if (process.env.VUE_APP_NOAUTH) {
  console.debug('Requested NO_AUTH mode');
  Vue.use(NoAuthPlugin);
} else {
  // Install the authentication plugin here
  Vue.use(Auth0Plugin, {
    domain: AUTH0_DOMAIN,
    clientId: AUTH0_CLIENT_ID,
    // @ts-expect-error lol
    onRedirectCallback: (appState) => {
      router.push(
        appState && appState.targetUrl
          ? appState.targetUrl
          : window.location.pathname,
      );
    },
  });
}

const store = createStore();
STORE_BRIDGE.store = store;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');

if (process.env.NODE_ENV === 'development') {
  const authCookieName = 'crystallizer_app_viewer_bearer_token';
  // @ts-expect-error extend window
  window.login = function login(jwt: string) {
    document.cookie = `${authCookieName}=${jwt}`;
  };
  // @ts-expect-error extend window
  window.logout = function logout() {
    document.cookie = `${authCookieName}=; Max-Age=-99999999;`;
  };

  const PLAN_NAME_TO_STRING_LINK = {
    ondemand: 'https://buy.stripe.com/test_bIYeYk9jo03K9tCeUV',
    monthly: 'https://buy.stripe.com/test_9AQbM8ansaIo35ecMO',
    annual: 'https://buy.stripe.com/test_7sI8zW67caIo35e6or',
  };

  const makeLink = function makeLink(planName: string, formattedUserId: string) {
    // @ts-expect-error no big deal
    return `${PLAN_NAME_TO_STRING_LINK[planName]}?client_reference_id=${formattedUserId}`;
  };

  // @ts-expect-error extend window
  window.purchasePlan = function purchasePlan(type: string, auth0id: string) {
    const url = makeLink(type, auth0id);
    window.open(url, '_blank')?.focus();
  };
}
