// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ValidatorFunc = (...args: any[]) => string | true

export function required(name: string) {
  return !!name || 'Required.';
}

export function alphaNum(name: string) {
  return /^[a-z0-9-_.*(),\s]+$/i.test(name) || 'Forbidden characters.';
}

export function makeNotIn(blacklist: string[], message: string) {
  return function notIn(name: string) {
    return blacklist.includes(name) ? message : true;
  };
}
