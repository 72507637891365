import { SupportedFileFormat } from '@/api/volume';

const FILE_INFERENCE_COLLECTION = [
  {
    type: 'DICOM',
    skip: 128,
    header: Array.from('DICM').map((c) => c.charCodeAt(0)),
  },
];

// How much data to read when extracting file magic.
// This should be generous enough for most files.
const HEAD_CHUNK = 512;

function prefixEquals(target: Uint8Array, prefix: number[] | Uint8Array) {
  if (prefix.length > target.length) {
    return false;
  }
  for (let i = 0; i < prefix.length; i += 1) {
    if (prefix[i] !== target[i]) {
      return false;
    }
  }
  return true;
}

export function inferFileType(file: File): Promise<SupportedFileFormat | undefined> {
  return new Promise((resolve: (type: SupportedFileFormat | undefined) => void) => {
    const head = file.slice(0, HEAD_CHUNK);
    const reader = new FileReader();
    reader.onload = () => {
      const chunk = new Uint8Array(reader.result as ArrayBuffer);
      for (let i = 0; i < FILE_INFERENCE_COLLECTION.length; i += 1) {
        const { type, header, skip = 0 } = FILE_INFERENCE_COLLECTION[i];
        if (prefixEquals(chunk.slice(skip), header)) {
          resolve(type as SupportedFileFormat | undefined);
          return;
        }
      }
      resolve(undefined);
    };
    reader.readAsArrayBuffer(head);
  });
}
