
import Vue from 'vue';
import UploadControl from '@/components/util/UploadControl.vue';
import { mapGetters, mapState } from 'vuex';
import { ITabItem } from '@/store/tabs.store';

export default Vue.extend({
  components: { UploadControl },
  props: {
    tabItem: {
      type: Object as () => ITabItem,
      required: true,
    },
  },
  computed: {
    ...mapGetters('upload', [
      'inProgress',
    ]),
    ...mapState('upload', [
      'filename',
    ]),
  },
});
