export function pick<T, K extends keyof T>(obj: T, ...keys: K[]): Pick<T, K> {
  return keys.reduce((o, k) => ({ ...o, [k]: obj[k] }), {} as Pick<T, K>);
}

export function chunk<T>(arr: Array<T>, chunkSize: number): Array<Array<T>> {
  const chunks = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    chunks.push(arr.slice(i, i + chunkSize));
  }
  return chunks;
}

export function deepMerge<T>(objs: Array<Record<string, Array<T>>>): Record<string, Array<T>> {
  const result: Record<string, Array<T>> = {};

  for (const obj of objs) {
    for (const key of Object.keys(obj)) {
      if (result[key]) {
        result[key].push(...obj[key]);
      } else {
        result[key] = [...obj[key]];
      }
    }
  }

  return result;
}
