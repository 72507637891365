import dayjs from 'dayjs';
import { performInternalApiCall } from '.';
import { IStudy } from './item';

export type ICreateStudyParams = Omit<IStudy, 'id' | 'created' | 'modified'>

export function create(params: ICreateStudyParams) {
  const replacer = function replacer(key: string, value: unknown) {
    if (key === 'study_date') {
      return dayjs(value as Date).format('YYYY-MM-DD');
    }

    return value;
  };

  return performInternalApiCall<IStudy>('study/w/', {
    method: 'POST',
    payload: JSON.stringify(params, replacer),
  });
}
