
import Vue from 'vue';
import { mapMutations, mapState } from 'vuex';
import DefaultEmptyTab from '@/components/DefaultEmptyTab.vue';
import ViewerAppWrapper from '@/viewer_app/ViewerAppWrapper.vue';

export default Vue.extend({
  components: { DefaultEmptyTab, ViewerAppWrapper },
  computed: {
    ...mapState('tabs', [
      'tabItems',
      'activeTabId',
    ]),
    ...mapState('treeview', [
      'volumes',
    ]),
  },
  watch: {
    tabItems: {
      handler() {
        // @ts-expect-error resize the slider
        this.$refs.tabs.callSlider();
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations('tabs', {
      setActiveTab: 'SET_ACTIVE_TAB',
      addTab: 'ADD_TAB',
      removeTab: 'REMOVE_TAB',
    }),
  },
});
