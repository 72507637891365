
import Vue from 'vue';
import {
  required, alphaNum, makeNotIn, ValidatorFunc,
} from '@/validators';

const defaultValidators = [required, alphaNum];

interface ChooseNameDialogData {
  name: string
  isFormValid: boolean
  fieldErrors: {
    name: boolean
  }
}

export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      required: false,
      default: () => null,
    },
    title: {
      type: String,
      required: false,
      default: () => 'Choose a name',
    },
    subtitle: {
      type: String,
      required: false,
      default: () => '',
    },
    saveText: {
      type: String,
      required: false,
      default: () => 'Save',
    },
    blacklist: {
      type: Array as () => string[],
      required: false,
      default: () => [],
    },
    errors: {
      type: Object as () => Record<string, string[]>,
      required: false,
      default: () => ({}),
    },
  },
  data: (): ChooseNameDialogData => ({
    name: '',
    isFormValid: false,
    fieldErrors: {
      name: false,
    },
  }),
  computed: {
    validators(): ValidatorFunc[] {
      return [
        ...defaultValidators,
        makeNotIn(this.blacklist, 'This name is already taken.'),
      ];
    },
  },
  watch: {
    errors(newErrors) {
      this.fieldErrors.name = !!newErrors.name;
    },
  },
  methods: {
    handleSaveClick() {
      this.$emit('save-clicked', { name: this.name });
    },
  },
});
