import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import ShareLinkAccessPage from '../pages/ShareLinkAccessPage.vue';
import AuthenticatedAccessPage from '../pages/AuthenticatedAccessPage.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  /**
   * Normal access for an authenticated user who has an account
   */
  {
    path: '/',
    name: 'viewer.authenticated',
    component: AuthenticatedAccessPage,
  },

  /**
   * Access via a share link by anyone who has the link
   */
  {
    path: '/share/:type(v|f)/:linkId',
    name: 'viewer.share',
    component: ShareLinkAccessPage,
    props: (route) => ({ linkId: route.params.linkId, itemType: route.params.type }),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
