
import Vue from 'vue';
import { create } from '@/api/volume';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { extractFormatFromFile, getFileFormat, getContainerFromMetadata } from '@/helpers/volume.helper';
import { IStudy, IUploadableVolume, VolumeDataFormat } from '@/api/item';
import { ITabItem } from '@/store/tabs.store';
import { addBeforeExtension } from '@/helpers/file';
import { create as createStudy } from '@/api/study';
import dayjs from 'dayjs';
import { DICOMIO } from '@/dicom';
import { pick, chunk, deepMerge } from '@/helpers/utils';
import { TooManyRequestError, catchError } from '@/helpers/errors';

export const ANONYMOUS_PATIENT = 'Anonymous';
export const ANONYMOUS_PATIENT_ID = 'ANONYMOUS';
export interface IVolumeInfo {
  info: Record<string, string>
  numberOfSlices: number
  filenames: string[]
}

const dcmio = new DICOMIO();
dcmio.initialize().then(() => {
  dcmio.readTags(new File([], 'empty.dcm'), []);
});

export default Vue.extend({
  props: {
    tabItem: {
      type: Object as () => ITabItem,
      required: false,
      default: () => null,
    },
  },
  computed: {
    ...mapGetters('auth', [
      'isAuthenticated',
    ]),
    ...mapGetters('upload', [
      'inProgress',
    ]),
    ...mapGetters('treeview', [
      'currentFolder',
    ]),
    uploadDisabled(): boolean {
      return (!this.isAuthenticated || this.inProgress);
    },
  },

  methods: {
    ...mapActions('upload', [
      'uploadVolumeData',
    ]),
    ...mapActions('treeview', [
      'loadFolder',
    ]),
    ...mapMutations('ui', {
      showSnackbar: 'SHOW_SNACKBAR',
      updatePromptBeforeLeave: 'UPDATE_PROMPT_BEFORE_LEAVE',
      showUpgradePlanDialog: 'SHOW_UPGRADE_PLAN_DIALOG',
    }),
    ...mapMutations('upload', {
      updateUploadStatus: 'UPDATE_UPLOAD_STATUS',
      updateUploadName: 'UPDATE_UPLOAD_NAME',
    }),
    openFileDialog() {
      (this.$refs.fileInput as HTMLInputElement).click();
    },
    async onFileInputChange(e: Event) {
      const { files: fileList } = (e.target as HTMLInputElement);
      if (!fileList || fileList.length === 0) {
        return;
      }

      // Make it an array
      const files = [...fileList];

      try {
        this.updateUploadStatus('CHECKING');
        this.updateUploadName(files[0].name);
        await this.performUpload(files);
      } catch (err) {
        this.showSnackbar({ type: 'error', message: (err as Error).message });
        throw e;
      } finally {
        this.updateUploadStatus('STALE');
        this.updatePromptBeforeLeave(false);
      }
    },
    async performUpload(files: File[]) {
      // Guess the volume data format from the first file of the list
      const firstFile = files[0];
      const fileFormat = await extractFormatFromFile(firstFile);
      let volumeDataFormat: VolumeDataFormat;
      let container = this.currentFolder.id;
      let metadata;
      this.updatePromptBeforeLeave(true);

      // List of upload volumes we get from the backend
      let volumes: IUploadableVolume[] = [];
      const nameToFileMap = new Map(files.map((f) => [f.name, f]));

      let patientName = ANONYMOUS_PATIENT;

      if (fileFormat === 'DICOM') {
        volumeDataFormat = files.length > 1 ? 'DICOM_FOLDER' : 'DICOM_SINGLE';

        const fileFormats = await Promise.all(files.slice(1).map((file) => getFileFormat(file)));

        // Make sure we don't have any non-DICOM files
        if (fileFormats.some((format) => format !== 'DICOM')) {
          throw new Error('The submitted file list contains one or more invalid DICOM files');
        }

        const volumesToFilesMaps = [];

        for (const chunkedFiles of chunk(files, 800)) {
          // eslint-disable-next-line no-await-in-loop
          volumesToFilesMaps.push(await dcmio.categorizeFiles(chunkedFiles));
        }

        // Extract DICOM informations
        const volumesToFilesMap = deepMerge(volumesToFilesMaps);

        const volumeInfoList = await Promise.all(Object.entries(volumesToFilesMap).map(
          async ([_, fileNames]: [string, string[]]) => {
            const volumeFiles: File[] = [];
            const filenames: string[] = [];
            fileNames.forEach((fileName) => {
              const file = nameToFileMap.get(fileName);
              if (file) {
                volumeFiles.push(file);
                filenames.push(fileName);
              }
            });

            // Now read the tags
            const numberOfSlices = volumeFiles.length;

            const info = await dcmio.readTags(volumeFiles[0], [
              { name: 'PatientName', tag: '0010|0010', strconv: true },
              { name: 'StudyInstanceUID', tag: '0020|000d' },
              { name: 'StudyDate', tag: '0008|0020' },
              { name: 'StudyDescription', tag: '0008|1030', strconv: true },
              { name: 'SeriesInstanceUID', tag: '0020|000e' },
              { name: 'SeriesDescription', tag: '0008|103e', strconv: true },
            ]);

            return {
              info,
              numberOfSlices,
              filenames,
            };
          },
        ));
        const firstVolume = volumeInfoList[0];

        if (firstVolume.info.PatientName) {
          patientName = firstVolume.info.PatientName;
        }

        const studyInfo = pick(
          firstVolume.info,
          'StudyInstanceUID',
          'StudyDescription',
          'StudyDate',
        );

        const studyDate = dayjs(studyInfo.StudyDate).toDate();

        // create the study
        let study: IStudy;
        try {
          study = await createStudy({
            study_instance_uid: studyInfo.StudyInstanceUID,
            study_description: studyInfo.StudyDescription,
            study_date: studyDate,
          });
        } catch (e) {
          catchError(e, [TooManyRequestError]);

          this.showSnackbar({ type: 'error', message: "You've reached the daily data import limit" });
          this.showUpgradePlanDialog('You exceeded your upload rate. Please consider upgrading your plan.');
          return;
        }
        // format YYYYmmdd
        container = getContainerFromMetadata(studyDate, patientName);
        volumes = await Promise.all(
          volumeInfoList.map(async (volumeInfo: IVolumeInfo) => {
            const volume = await create({
              name: volumeInfo.info.SeriesDescription,
              filenames: volumeInfo.filenames,
              dataFormat: 'DICOM_FOLDER',
              container,
              metadata: {
                patientName: volumeInfo.info.PatientName,
                acquisitionDate: new Date(study.study_date),
                seriesDescription: volumeInfo.info.SeriesDescription,
              },
              studyId: study.id,
            });

            volume.fileList = [];
            for (const filename of volumeInfo.filenames) {
              const file = nameToFileMap.get(filename);
              if (file) {
                volume.fileList.push(file);
              }
            }
            return volume;
          }),
        );
      } else {
        volumeDataFormat = fileFormat;
        const formattedImportDate = dayjs().format('YYYY-MM-DD_HH-mm');
        const volumeName = addBeforeExtension(files[0].name, `_${formattedImportDate}`);

        try {
          const volume = await create({
            name: volumeName,
            filenames: files.map((file) => file.name),
            dataFormat: volumeDataFormat,
            container,
            metadata,
          });

          volume.fileList = files;
          volumes.push(volume);
        } catch (e) {
          catchError(e, [TooManyRequestError]);

          this.showSnackbar({ type: 'error', message: "You've reached the daily data import limit" });
          this.showUpgradePlanDialog(`
          You've reached the daily limit of 2 data imports.<br>
          You can try it for free again tomorrow.<br>
          <br>
          To enjoy unlimited access, please upgrade to a Pro subscription.
          `);
          return;
        }
      }

      this.$emit('upload-starting', volumes);

      const maxNumberOfQueuedFileUpload = 800;
      let currentNumberOfQueuedFileUploads = 0;
      const volumeUploadQueue: Array<{ promise: Promise<void>, numberofFiles: number }> = [];
      for (const volumeInfo of volumes) {
        while (currentNumberOfQueuedFileUploads > maxNumberOfQueuedFileUpload) {
          // eslint-disable-next-line no-await-in-loop
          await volumeUploadQueue[0].promise;
          currentNumberOfQueuedFileUploads -= volumeUploadQueue[0].numberofFiles;
          volumeUploadQueue.shift();
        }

        const uploadPromise = this.uploadVolumeData({ volume: volumeInfo, maxNumberOfQueuedFileUpload });
        volumeUploadQueue.push({ promise: uploadPromise, numberofFiles: volumeInfo.fileList.length });
        currentNumberOfQueuedFileUploads += volumeInfo.fileList.length;
      }

      await Promise.all(volumeUploadQueue.map(({ promise }) => promise));
      this.updatePromptBeforeLeave(false);

      this.loadFolder({ folderId: this.currentFolder.id });
      this.$emit('upload-completed', volumes);
    },
  },
});
