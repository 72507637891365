import { Uuid } from '@/types';
import { performInternalApiCall } from '.';

export function all() {
  return performInternalApiCall<unknown[]>('session_slot/r/all/');
}

export function acquire(id: Uuid) {
  return performInternalApiCall(`session_slot/w/${id}/acquire/`, {
    method: 'POST',
  });
}
