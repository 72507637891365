
import Vue from 'vue';
import { mapGetters, mapMutations, mapState } from 'vuex';
import { PRICING_REDIRECT_URI } from '../../../auth_config';

const menus = [
  {
    name: 'File',
    items: [
      { slug: 'new-tab', text: 'New tab' },
      { slug: 'close-tab', text: 'Close tab' },
      { slug: 'save', text: 'Save' },
      { slug: 'save-as', text: 'Save as' },
      { slug: 'rename', text: 'Rename' },
      { slug: 'download', text: 'Download' },
      { slug: 'export-as', text: 'Export as' },
      { slug: 'print', text: 'Print' },
    ],
  },
  {
    name: 'Share',
    items: [
      { slug: 'share-active-volume', text: 'Share active volume' },
      { slug: 'share-current-folder', text: 'Share currently opened folder' },
    ],
  },
];

export default Vue.extend({
  data: () => ({
    menus,
    pricingUrl: PRICING_REDIRECT_URI,
    enabled: {
      share: true,
      file: true,
      items: {
        rename: true,
        'new-tab': true,
        'close-tab': true,
        save: true,
        'save-as': true,
        download: true,
        'export-as': true,
        print: true,
        'share-active-volume': true,
        'share-current-folder': true,
      },
    },
  }),
  computed: {
    ...mapGetters('tabs', [
      'currentVolume',
      'activeTab',
    ]),
    ...mapGetters('treeview', [
      'currentFolder',
      'currentFolderIsRoot',
    ]),
    ...mapGetters('auth', [
      'isAuthenticated',
      'sharedFolder',
    ]),
    ...mapState('ui', [
      'sidebar',
    ]),
    ...mapState('tasks', [
      'exportInProgress',
    ]),
    ...mapState('auth', [
      'freeAccount',
    ]),
    showMenuButton(): boolean {
      return this.isAuthenticated || this.sharedFolder;
    },
  },
  mounted() {
    // Disable the share menu dropdown if we're not in authenticated access
    this.enabled.share = this.isAuthenticated;
    this.enabled.items.rename = this.isAuthenticated;
    this.enabled.items.save = this.isAuthenticated;
    this.enabled.items['save-as'] = this.isAuthenticated;

    // The items below should always remain disabled in case of a share link access
    if (this.isAuthenticated) {
      this.$watch(() => this.currentVolume, () => {
        this.enabled.items.rename = this.currentVolume !== null;
        this.enabled.items['share-active-volume'] = this.currentVolume !== null;
        this.enabled.items.save = this.currentVolume !== null;
        this.enabled.items['save-as'] = this.currentVolume !== null && !this.currentVolume.draft;
      }, {
        immediate: true,
        deep: true,
      });

      this.$watch(
        () => [this.enabled.items['share-current-folder'], this.enabled.items['share-active-volume']],
        ([folderShareEnabled, volumeShareEnabled]) => {
          this.enabled.share = folderShareEnabled || volumeShareEnabled;
        },
      );
    }

    // Disable some menu items if there's no current volume
    this.$watch(() => [this.currentVolume, this.exportInProgress], ([currentVolume, exportInProgress]) => {
      this.enabled.items.download = currentVolume !== null;
      this.enabled.items.print = currentVolume !== null;

      // Do not enable VTI for now
      const enableExportAs = currentVolume !== null
        && this.currentVolume.draft !== true
        && ['NIFTI', 'DICOM_FOLDER'].includes(currentVolume.data_format)
        && !exportInProgress;

      this.enabled.items['export-as'] = enableExportAs;
    }, {
      immediate: true,
      deep: true,
    });

    this.$watch(() => this.currentFolder, () => {
      // Disallow sharing the root folder
      this.enabled.items['share-current-folder'] = !this.currentFolderIsRoot;
    }, {
      immediate: true,
    });

    this.$watch(() => this.activeTab, () => {
      this.enabled.items['close-tab'] = this.activeTab != null;
    }, {
      immediate: true,
    });
  },
  methods: {
    ...mapMutations('ui', {
      toggleSidebar: 'TOGGLE_SIDEBAR',
    }),
  },
});
