/* eslint-disable */
import { SupportedExtension, SupportedFileFormat } from '@/api/volume';
import { IViewerState, ViewerApp } from '.';

type IVolViewWindow = Window

export default class VolViewApp implements ViewerApp {
  iframe: HTMLIFrameElement

  #window: IVolViewWindow

  /**
   * @param iframe The iframe must be loaded
   */
  constructor(iframe: HTMLIFrameElement) {
    this.iframe = iframe;
    this.#window = (this.iframe.contentWindow as IVolViewWindow);
  }

  async unloadVolume() {
    // todo
  }

  async openRemoteVolume(urls: string[], state?: IViewerState) {
    // todo
  }

  getHasLoadedVolume() {
    return false;
  }
  async openLocalVolume(files: File[], extensions: SupportedExtension[]) {
    // todo
  }

  async getCurrentState() {
    // todo
    return {
      userData: {},
      options: {},
      sources: [],
      views: [],
      representations: [],
      fields: {}
    } as unknown as IViewerState
  }

  async takeScreenshots(): Promise<string[]> {
    // todo
    return []
  }

  openDrawerPanel() {
    // todo
  }
}
