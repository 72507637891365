import Vue from 'vue';
import Vuex from 'vuex';
import treeview from './treeview.store';
import tabs from './tabs.store';
import auth from './auth.store';
import ui from './ui.store';
import upload from './upload.store';
import tasks from './tasks.store';

Vue.use(Vuex);

export function createStore() {
  return new Vuex.Store({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {
      treeview,
      tabs,
      auth,
      ui,
      upload,
      tasks,
    },
  });
}
