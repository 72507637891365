
import { request } from '@/api/item';
import Vue from 'vue';
import MainWebApp from '@/components/MainWebApp.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import {
  catchError, ForbiddenError, GoneError, NotFoundError, UnauthorizedError,
} from '@/helpers/errors';

interface ISharedLinkPageData {
  status: string
}

export default Vue.extend({
  components: {
    MainWebApp,
  },
  props: {
    linkId: {
      type: String,
      required: true,
    },
    itemType: {
      type: String as () => 'f' | 'v',
      required: true,
    },
  },

  data: (): ISharedLinkPageData => ({
    status: 'loading',
  }),

  computed: {
    ...mapGetters('tabs', [
      'activeTab',
    ]),
  },
  async created() {
    this.setAccessType('share');
    this.setShareLinkInfo({ shareLinkType: this.itemType, shareLinkId: this.linkId });

    // Unauthenticated access: request access to the item shared by the link
    try {
      const item = await request(this.itemType, this.linkId);
      this.status = 'loaded';

      // At this point we know that the link if valid.
      if (this.itemType === 'f') {
        this.registerFolder(item);
        this.setCurrentFolderId(item.id);
      } else {
        this.addOrUpdateVolume(item);
        this.openVolume({ volume: item, tabItem: this.activeTab });
      }
    } catch (e) {
      catchError(e, [NotFoundError, GoneError, UnauthorizedError, ForbiddenError]);

      if (e instanceof NotFoundError) {
        // In this case the server did not find the link
        this.status = 'notfound';
      } else if (e instanceof GoneError) {
        this.status = 'expired';
      } else if (e instanceof UnauthorizedError || e instanceof ForbiddenError) {
        this.status = 'no_available_slot';
      }
    }
  },

  methods: {
    ...mapActions('treeview', [
      'loadFolder',
      'loadVolume',
      'registerFolder',
    ]),
    ...mapActions('tabs', [
      'openVolume',
    ]),
    ...mapMutations('treeview', {
      addOrUpdateVolume: 'ADD_OR_UPDATE_VOLUME',
      setCurrentFolderId: 'SET_CURRENT_FOLDER_ID',
    }),
    ...mapMutations('auth', {
      setAccessType: 'SET_ACCESS_TYPE',
      setShareLinkInfo: 'SET_SHARE_LINK_INFO',
    }),
  },
});
