import { Uuid } from '@/types';
import { Module } from 'vuex';
import { IRootState } from './types';

export enum AuthorizationStatus {
  ACTIVE, // User is authenticated and authorized
  INACTIVE, // User is authenticated but not active (e.g. no plan is currently active)
  UNAUTHORIZED, // User is not authenticated
  UNAUTHORIZED_INTERRUPTED, // User is not authenticated but once was during the current session
  NO_AVAILABLE_SLOT, // User is authenticated, but no slot is available for him
  UNKNOWN, // Status is not currently known
}

export interface IAuthStoreState {
  accessType: null | 'authenticated' | 'share'
  freeAccount: boolean
  shareLinkId: null | Uuid
  shareLinkType: null | 'v' | 'f'
  status: AuthorizationStatus
}

export default {
  state: {
    accessType: null,
    freeAccount: false,
    shareLinkId: null,
    shareLinkType: null,
    status: AuthorizationStatus.UNKNOWN,
  },
  getters: {
    isAuthenticated: (state) => state.accessType === 'authenticated',
    sharedFolder: (state) => state.accessType === 'share' && state.shareLinkType === 'f',
  },
  mutations: {
    SET_ACCESS_TYPE(state, accessType: 'authenticated' | 'share') {
      state.accessType = accessType;
    },
    SET_SHARE_LINK_INFO(state, { shareLinkId, shareLinkType }: { shareLinkId: Uuid, shareLinkType: 'v' | 'f' }) {
      state.shareLinkId = shareLinkId;
      state.shareLinkType = shareLinkType;
    },
    SET_STATUS(state, status: AuthorizationStatus) {
      state.status = status;
    },
    SET_FREE_ACCOUNT(state, freeAccount: boolean) {
      state.freeAccount = freeAccount;
    },
  },
  actions: {},
  namespaced: true,
} as Module<IAuthStoreState, IRootState>;
