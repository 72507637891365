
import Vue from 'vue';
import { Uuid } from '@/types';

interface ISessionSlotsTableData {
  headers: Array<{text: string, value: string}>
}

export default Vue.extend({
  props: {
    sessionSlots: {
      type: Array,
      required: true,
    },
  },
  data(): ISessionSlotsTableData {
    return {
      headers: [
        {
          text: 'Started at',
          value: 'acquired_at',
        },
        {
          text: 'Last activity at',
          value: 'last_activity_at',
        },
        {
          text: 'Access',
          value: 'access_type',
        },
        {
          text: 'Actions',
          value: 'actions',
        },
      ],
    };
  },
  methods: {
    async acquireSlot(id: Uuid) {
      this.$emit('acquire', { id });
    },
  },
});
