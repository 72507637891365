export type Uuid = string
export type SerializedDate = string

export interface IApiEntity {
  id: Uuid
  created: SerializedDate
  modified: SerializedDate
}

export const VOLUME_EXPORT_FILE_FORMATS = ['jpeg', 'png', 'nii'] as const;
export const VOLUME_EXPORT_TARGET_BUNDLES = ['pdf', 'zip'] as const;
export type VolumeExportTargetFileFormat = typeof VOLUME_EXPORT_FILE_FORMATS[number];
export type VolumeExportTargetBundleFormat = typeof VOLUME_EXPORT_TARGET_BUNDLES[number];
