import { SupportedExtension } from '@/api/volume';
import type { ITabItem } from '@/store/tabs.store';

export const VIEWER_APP_STATIC_URL: string = process.env.VUE_APP_VIEWER_APP_STATIC_URL as string;

export interface IViewerStateSource {
  id: string
  group: string
  name: string
  props: {
    name: string
    type: string
    dataset: {
      name?: string
      serializedType?: string
      url?: string
      seriesUrls?: string[]
    }
  }
}
export interface IViewerStateUserData {
  version: number
  activeSourceId: string
  store: {
    route: object
    views: object
    widgets: object
  }
  $oldToNewIdMapping: object
}

export interface IViewerState {
  userData: IViewerStateUserData
  options: object
  sources: IViewerStateSource[]
  views: object[]
  representations: object[]
  fields: object
}

export interface ViewerApp {
  openRemoteVolume: (urls: string[], state?: IViewerState) => Promise<void>
  openLocalVolume: (files: File[], extensions: SupportedExtension[]) => Promise<void>
  unloadVolume: () => void
  getCurrentState: () => Promise<IViewerState>
  takeScreenshots: () => Promise<string[]>
  getHasLoadedVolume: () => boolean
  openDrawerPanel: () => void
}

export const viewerApps = new WeakMap<ITabItem, ViewerApp>();
