import { ILoadableVolume, VolumeDataFormat } from '@/api/item';
import {
  EXTENSIONS_TO_FORMATS,
  IVolumeMetadata, MIME_TYPES_TO_FORMATS, SupportedFileFormat,
} from '@/api/volume';
import dayjs from 'dayjs';
import { inferFileType } from './io';
import { extractFilenameFromUrl } from './url';

/**
 * Download volume data
 *
 * @param volume the volume to download
 */
export async function downloadVolumeData(volume: ILoadableVolume) {
  if (volume.fileUrls.length === 0) {
    throw new Error('Cannot download volume data with no file URL');
  }

  const responses = await Promise.all(volume.fileUrls.map((url) => fetch(url)));

  return Promise.all(responses.map(async (response) => ({
    blob: await response.blob(),
    filename: extractFilenameFromUrl(response.url),
  })));
}

export async function getFileFormat(file: File): Promise<SupportedFileFormat | undefined> {
  let fileFormat: SupportedFileFormat | undefined;
  const { type } = file;

  if (type) {
    // @ts-expect-error we get type at runtime
    fileFormat = MIME_TYPES_TO_FORMATS[type];
  }

  if (!fileFormat) {
    const ext = file.name.split('.').pop()?.toLowerCase();
    // @ts-expect-error we get ext at runtime
    fileFormat = EXTENSIONS_TO_FORMATS[ext];
  }

  if (!fileFormat) {
    fileFormat = await inferFileType(file);
  }

  return fileFormat;
}

export async function extractFormatFromFile(file: File) {
  const fileFormat: SupportedFileFormat | undefined = await getFileFormat(file);

  if (!fileFormat) {
    throw new Error('Cannot extract format from file.');
  }

  return fileFormat;
}

export const VOLUME_DATA_FORMAT_TO_FILE_FORMAT: Record<VolumeDataFormat, SupportedFileFormat> = {
  DICOM_FOLDER: 'DICOM',
  DICOM_SINGLE: 'DICOM',
  NIFTI: 'NIFTI',
  NRRD: 'NRRD',
  VTI: 'VTI',
} as const;

export function volumeDataFormatToFileFormat(format: VolumeDataFormat) {
  return VOLUME_DATA_FORMAT_TO_FILE_FORMAT[format];
}

export function getContainerFromMetadata(acquisitionDate: Date, patientName: string) {
  const studyDate = acquisitionDate ?? new Date();

  const formattedStudyDate = dayjs(studyDate).format('YYYY-MM-DD_HH-mm');
  const patient = patientName ?? 'Anonymous';

  const container = [
    `${patient}_${formattedStudyDate}`,
  ];

  return container;
}
