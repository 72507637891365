import GlanceApp from './GlanceApp';
import VolViewApp from './VolViewApp';

const VIEWER_APP_TO_CONSTRUCTOR = {
  VOLVIEW: VolViewApp,
  GLANCE: GlanceApp,
};

const VIEWER_APP = process.env.VUE_APP_VIEWER_APP as 'VOLVIEW' | 'GLANCE';

export const ViewerAppConstructor = VIEWER_APP_TO_CONSTRUCTOR[VIEWER_APP];
