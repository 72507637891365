import {
  content, create,
} from '@/api/folder';
import { get, update } from '@/api/volume';
import {
  IFolder, IFolderWithContent, IVolume, VolumeId,
  IStudy,
  FolderId,
} from '@/api/item';
import { Uuid } from '@/types';
import Vue from 'vue';
import { Module } from 'vuex';
import { IRootState } from './types';
import search from './search.store';

export type StoreFolder = Omit<IFolder, 'volumes'> & { volumeIds: Uuid[], studyIds: Uuid[] };

interface ITreeViewStoreState {
  volumes: Record<Uuid, IVolume>
  folders: Record<Uuid, StoreFolder>
  currentFolderId: Uuid | null
  rootFolderId: Uuid | null
  studies: Record<Uuid, IStudy>
  activeStudyId: Uuid | null
}

interface ILoadFolderOptions {
  // folder to load. 'root' if useful if we want to load the user's root folder without knowing its id
  folderId: FolderId | 'root'
  // If true, the folder being loaded will be set as the new current folder
  activate: boolean
  studyId?: Uuid
}

export default {
  state: {
    volumes: {},
    folders: {},
    currentFolderId: null,
    rootFolderId: null,
    studies: {},
    activeStudyId: null,
  },
  getters: {
    folderVolumes(state) {
      return function folderVolumesGetter(folderId: Uuid) {
        return state.folders[folderId]
          .volumeIds
          .map((volumeId) => state.volumes[volumeId]);
      };
    },
    folderStudies(state) {
      return function folderStudiesGetter(folderId: Uuid) {
        return state.folders[folderId]
          .studyIds
          .map((studyId) => state.studies[studyId]);
      };
    },
    currentFolderVolumes(state, getters) {
      return state.currentFolderId ? getters.folderVolumes(state.currentFolderId) : [];
    },
    currentFolderStudies(state, getters) {
      return state.currentFolderId ? getters.folderStudies(state.currentFolderId) : [];
    },
    currentFolder(state) {
      return state.currentFolderId ? state.folders[state.currentFolderId] : null;
    },
    currentFolderIsRoot(state) {
      return state.currentFolderId === state.rootFolderId;
    },
    isVolumeInCurrentFolder: (state, getters) => (volumeId: Uuid) => getters.currentFolder.volumeIds.includes(volumeId),
    folder: (state) => (folderId: Uuid) => state.folders[folderId],
  },
  mutations: {
    ACTIVATE_STUDY(state, studyId: Uuid) {
      state.activeStudyId = studyId;
    },
    ADD_OR_UPDATE_VOLUME(state, volume: IVolume) {
      if (!state.volumes[volume.id]) {
        Vue.set(state.volumes, volume.id, volume);
      } else {
        Object.assign(state.volumes[volume.id], volume);
      }
    },
    ADD_OR_UPDATE_FOLDER(state, folder: IFolder) {
      if (!state.folders[folder.id]) {
        Vue.set(state.folders, folder.id, folder);
      } else {
        Object.assign(state.folders[folder.id], folder);
      }
    },
    ADD_OR_UPDATE_STUDY(state, study: IStudy) {
      if (!state.studies[study.id]) {
        Vue.set(state.studies, study.id, study);
      } else {
        Object.assign(state.studies[study.id], study);
      }
    },
    ADD_FOLDER_TO_CURRENT(state, folder: IFolder) {
      if (state.currentFolderId) {
        (state.folders[state.currentFolderId] as unknown as IFolderWithContent).folders.push(folder);
      }
    },
    SET_CURRENT_FOLDER_ID(state, folderId: Uuid) {
      state.currentFolderId = folderId;
    },
    SET_ROOT_FOLDER_ID(state, rootFolderId: Uuid) {
      state.rootFolderId = rootFolderId;
    },
  },
  actions: {
    registerFolder({ commit }, folder: IFolderWithContent) {
      const volumeIds: VolumeId[] = [];
      const studyIds: Uuid[] = [];

      if (folder.volumes) {
        folder.volumes.forEach((volume) => {
          // eslint-disable-next-line no-param-reassign
          volume.folder = folder.id;
          commit('ADD_OR_UPDATE_VOLUME', volume);
          volumeIds.push(volume.id);
        });
      }

      if (folder.studies) {
        folder.studies.forEach((study) => {
          commit('ADD_OR_UPDATE_STUDY', study);
          studyIds.push(study.id);
        });
      }

      commit('ADD_OR_UPDATE_FOLDER', {
        id: folder.id,
        name: folder.name,
        parentChain: folder.parentChain,
        folders: folder.folders || [],
        volumeIds,
        studyIds,
      });
    },
    /**
     * Fetch the content of the given folder and make it the current one if activate is true.
     * Add the content to the store.
     */
    async loadFolder({ commit, dispatch }, { folderId, studyId, activate = true }: ILoadFolderOptions) {
      const params = folderId === 'root' ? { root: true } : { folderId };

      const folder = await content(params);
      dispatch('registerFolder', folder);

      if (folderId === 'root') {
        commit('auth/SET_FREE_ACCOUNT', folder.free_account, {
          root: true,
        });
      }

      if (activate) {
        commit('SET_CURRENT_FOLDER_ID', folder.id);
      }

      if (folderId === 'root') {
        commit('SET_ROOT_FOLDER_ID', folder.id);
      }

      if (studyId) {
        commit('ACTIVATE_STUDY', studyId);
      }

      return folder;
    },

    async getFolder({ dispatch, state }, { folderId }: { folderId: Uuid }) {
      const folder = state.folders[folderId];
      if (folder) {
        return folder;
      }
      return dispatch('loadFolder', { folderId, activate: false });
    },

    async loadVolume(
      { commit, state },
      { volumeId, signedUrl, state: volumeState }: { volumeId: Uuid, signedUrl: boolean, state: boolean },
    ) {
      const volume = await get(volumeId, signedUrl, volumeState);
      commit('ADD_OR_UPDATE_VOLUME', volume);
      return state.volumes[volume.id];
    },

    async updateVolume({ commit }, volume: IVolume) {
      const updatedVolume = await update(volume);
      commit('ADD_OR_UPDATE_VOLUME', updatedVolume);
      return updatedVolume;
    },

    async createFolder({
      commit, state, dispatch,
    }, { parentFolderId, name }: { parentFolderId: Uuid, name: string }) {
      const folder = await create({
        parentFolderId,
        name,
      });

      dispatch('registerFolder', folder);

      if (state.currentFolderId === parentFolderId) {
        commit('ADD_FOLDER_TO_CURRENT', folder);
      }
    },
  },
  namespaced: true,
  modules: {
    search,
  },
} as Module<ITreeViewStoreState, IRootState>;
