import { IApiEntity, Uuid } from '@/types';
import type { IViewerState } from '@/viewer_app';
import { performInternalApiCall } from '.';

export type VolumeId = Uuid
export type FolderId = Uuid
export type ItemId = VolumeId | FolderId
export type VolumeDataFormat = 'NIFTI' | 'NRRD' | 'DICOM_SINGLE' | 'DICOM_FOLDER' | 'VTI'

export interface IItem extends IApiEntity {
  parentChain?: Array<{ id: Uuid, name: string }> // an ordered array of the entire parent folder chain of this item
  name: string
}

export interface IFolder extends IItem {
  parent: Uuid
}

export interface IVolume extends IItem {
  acquisition_date: string
  data_format: VolumeDataFormat
  patient_name: string
  series_description: string
  folder: Uuid
  draft: boolean
  study: Uuid
}

export interface IUploadableVolume extends IVolume {
  fileList: File[] // list of files for the volume data
  uploadUrls: Record<string, string> // maps filename with an AWS upload URL
}

export interface ILoadableVolume extends IVolume {
  fileUrls: string[]
  state?: IViewerState
}

export interface IStudy extends IApiEntity {
  id: Uuid
  study_description: string
  study_instance_uid: string
  study_date: Date
  parentChain?: Array<{ id: Uuid, name: string }> // an ordered array of the entire parent folder chain of this item
  volumes?: Array<IVolume>
}

export interface IFolderWithContent extends IItem {
  volumes: IVolume[] // volumes that belongs to this folder
  folders: IFolder[] // folders that belongs to this folder
  studies: IStudy[]
}

interface ISearchResult {
  folders: IFolder[]
  volumes: IVolume[]
}

type RequestItemResult = IFolderWithContent | ILoadableVolume & { state: IViewerState };

/**
 * Call the search API endpoint.
 *
 * @param folderId The id of the folder to search in
 * @param searchQuery the string used to filter folder/volume names
 */
export async function search(folderId: Uuid, searchQuery: string) {
  return performInternalApiCall<ISearchResult>(`item/r/search/?folder_id=${folderId}&search_query=${searchQuery}`);
}

/**
 * Request a share link for the given item id.
 *
 * @param itemId
 */
export async function share(itemType: 'volume' | 'folder', itemId: ItemId, state?: IViewerState) {
  return performInternalApiCall<{ link: string }>('item/w/share/', {
    method: 'POST',
    payload: JSON.stringify({
      itemId,
      itemType,
      state,
    }),
  });
}

export function request(itemType: 'v' | 'f', linkId: Uuid) {
  return performInternalApiCall<RequestItemResult>(`item/r/${linkId}/request/?type=${itemType}`, {
    unauthenticated: true,
  });
}
