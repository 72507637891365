
import Vue from 'vue';
import { mapMutations } from 'vuex';
import { PRICING_REDIRECT_URI } from '../../../auth_config';

export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      required: false,
      default: () => null,
    },
    message: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      pricingUrl: PRICING_REDIRECT_URI,
    };
  },
  methods: {
    ...mapMutations('ui', {
      hideUpgradePlanDialog: 'HIDE_UPGRADE_PLAN_DIALOG',
    }),
  },
});
