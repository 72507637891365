var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"tab-system-wrapper"}},[_c('div',{staticStyle:{"display":"flex","max-width":"100%"}},[_c('v-tabs',{ref:"tabs",staticStyle:{"max-width":"70%"},attrs:{"value":_vm.activeTabId},on:{"change":function($event){return _vm.setActiveTab($event)}}},[_vm._l((_vm.tabItems),function(tabItem){return [_c('v-hover',{key:tabItem.id + 'hover',scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-tab',{staticClass:"tab-item-title",attrs:{"href":`#${tabItem.id}`}},[_c('span',[(tabItem.volume && tabItem.volume.draft)?[_vm._v(" PREVIEW -  ")]:_vm._e(),_vm._v(" "+_vm._s(tabItem.name)+" ")],2),_c('v-btn',{staticClass:"ml-3",class:{ 'show-close': hover },attrs:{"icon":"","x-small":"","color":"transparent"},on:{"click":function($event){return _vm.removeTab(tabItem.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-close ")])],1)],1)]}}],null,true)}),(_vm.tabItems.indexOf(tabItem) !== _vm.tabItems.length - 1)?_c('v-divider',{key:tabItem.id + 'divider',attrs:{"vertical":""}}):_vm._e()]}),_c('v-btn',{staticClass:"align-self-center",attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.addTab({ activate: true })}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],2),_c('v-spacer'),_c('a',{staticClass:"my-auto",staticStyle:{"overflow":"visible"},attrs:{"href":"https://crystallizer.com","target":"_blank"}},[_c('span',{staticStyle:{"display":"none"}},[_vm._v(" Crystallizer Logo ")]),_c('v-img',{attrs:{"src":"/logo_dark.webp","width":200,"alt":"logo"}})],1)],1),_c('v-tabs-items',{attrs:{"id":"viewer-tab-items","value":_vm.activeTabId}},_vm._l((_vm.tabItems),function(tabItem){return _c('v-tab-item',{key:tabItem.id,staticClass:"viewer-tab-item",attrs:{"value":tabItem.id}},[(!tabItem.volume || tabItem.status === 'LOADING')?_c('default-empty-tab',{attrs:{"tab-item":tabItem},on:{"upload-completed":function($event){return _vm.$emit('upload-completed', {
          volumes: $event,
          tabItem
        })},"upload-starting":function($event){return _vm.$emit('upload-starting', {
          volumes: $event,
          tabItem
        })}}}):_vm._e(),_c('viewer-app-wrapper',{directives:[{name:"show",rawName:"v-show",value:(tabItem.volume && tabItem.status === 'LOADED'),expression:"tabItem.volume && tabItem.status === 'LOADED'"}],attrs:{"volume":tabItem.volume ? _vm.volumes[tabItem.volume.id] : undefined,"tab-item":tabItem}})],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }