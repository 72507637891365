import { Module } from 'vuex';
import { IRootState } from './types';

type SnackbarType = 'success' | 'error' | 'warning' | 'info'
type SnackbarIcon = 'mdi-information-outline' | 'mdi-check-circle' | 'mdi-alert-outline' | 'mdi-alert-octagon-outline'

interface IUiStoreState {
  snackbar: {
    visible: boolean
    message: string
    type: SnackbarType
    icon: SnackbarIcon
  }
  upgradePlanDialog: {
    visible: boolean
    message: string
  }
  promptBeforeLeave: boolean
  sidebar: {
    visible: boolean
  }
}

export default {
  state: {
    snackbar: {
      visible: false,
      message: '',
      type: 'success',
      icon: 'mdi-check-circle',
    },
    upgradePlanDialog: {
      visible: false,
      message: '',
    },
    sidebar: {
      visible: true,
    },
    promptBeforeLeave: false,
  },
  getters: {},
  mutations: {
    TOGGLE_SIDEBAR(state) {
      state.sidebar.visible = !state.sidebar.visible;
    },
    SHOW_SNACKBAR(state, { message, type }: { message: string, type: SnackbarType }) {
      state.snackbar.message = message;
      state.snackbar.type = type;
      state.snackbar.visible = true;

      switch (type) {
        case 'info':
          state.snackbar.icon = 'mdi-information-outline';
          break;
        case 'success':
          state.snackbar.icon = 'mdi-check-circle';
          break;
        case 'warning':
          state.snackbar.icon = 'mdi-alert-outline';
          break;
        case 'error':
          state.snackbar.icon = 'mdi-alert-octagon-outline';
          break;
        default:
      }
    },
    SHOW_UPGRADE_PLAN_DIALOG(state, message: string) {
      state.upgradePlanDialog.visible = true;
      state.upgradePlanDialog.message = message;
    },
    HIDE_UPGRADE_PLAN_DIALOG(state) {
      state.upgradePlanDialog.visible = false;
      state.upgradePlanDialog.message = '';
    },
    HIDE_SNACKBAR(state) {
      state.snackbar.visible = false;
      state.snackbar.message = '';
      state.snackbar.type = 'success';
      state.snackbar.icon = 'mdi-check-circle';
    },
    UPDATE_PROMPT_BEFORE_LEAVE(state, promptBeforeLeave: boolean) {
      state.promptBeforeLeave = promptBeforeLeave;
    },
  },
  actions: {},
  namespaced: true,
} as Module<IUiStoreState, IRootState>;
