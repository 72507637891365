import type { VolumeExportTargetBundleFormat, VolumeExportTargetFileFormat } from '@/types';
import { Uuid } from '@/types';
import dayjs from 'dayjs';
import {
  IUploadableVolume, IVolume, VolumeDataFormat, VolumeId,
} from './item';
import {
  performInternalApiCall, performFileUpload, performApiFileDownload,
} from '.';

export const MIME_TYPES_TO_FORMATS = {
  'application/dicom': 'DICOM',
} as const;

export const EXTENSIONS_TO_FORMATS = {
  nii: 'NIFTI',
  nrrd: 'NRRD',
  dcm: 'DICOM',
  dicom: 'DICOM',
  vti: 'VTI',
} as const;

export type SupportedFileFormat = typeof EXTENSIONS_TO_FORMATS[keyof typeof EXTENSIONS_TO_FORMATS];
export type SupportedMimeType = keyof typeof MIME_TYPES_TO_FORMATS
export type SupportedExtension = keyof typeof EXTENSIONS_TO_FORMATS
export const SUPPORTED_EXTENSIONS = Object.keys(EXTENSIONS_TO_FORMATS) as SupportedExtension[];

export function getFileFormatExtensions(fileFormat: SupportedFileFormat) {
  return SUPPORTED_EXTENSIONS.filter(
    (extension) => EXTENSIONS_TO_FORMATS[extension] === fileFormat,
  );
}

export interface IVolumeMetadata {
  patientName: string
  acquisitionDate: Date
  seriesDescription: string
}

export interface ICreateVolumePayload {
  name: string
  filenames?: string[]
  dataFormat?: VolumeDataFormat
  /**
   * Container can be an array of folder names: the desired folder hierarchy above the volume
   * Also can be a UUID: the id of the desired parent folder
   */
  container: string[] | Uuid
  metadata?: IVolumeMetadata
  studyId?: Uuid
}

/**
 * Retrieve volume informations
 *
 * @param volumeId id of the volume to retrieve
 * @param signedUrl true if you want to get a signed url to access the volume data
 * @param state true if you want to get the latest volume state
 * @returns
 */
export function get(volumeId: Uuid, signedUrl = false, state = false) {
  return performInternalApiCall<IVolume>(`volume/r/${volumeId}/get/?signed_url=${signedUrl}&state=${state}`);
}

export function update(volume: Partial<IVolume>) {
  return performInternalApiCall<IVolume>(`volume/w/${volume.id}/`, {
    payload: JSON.stringify(volume),
    method: 'PATCH',
  });
}

/**
 * Create a new volume
 *
 * @param numberOfFiles How many files needs to be uploaded for the volume data
 * @param dataFormat Format of the raw data
 * @returns
 */
export function create(volumeData: ICreateVolumePayload, fromVolume?: VolumeId) {
  const replacer = function replacer(key: string, value: unknown) {
    if (key === 'acquisitionDate') {
      return dayjs(value as Date).format('YYYY-MM-DD');
    }
    return value;
  };

  // Create a volume that will hold the files on the backend
  return performInternalApiCall<IUploadableVolume>('volume/w/', {
    method: 'POST',
    payload: JSON.stringify({
      ...volumeData,
      fromVolume,
    }, replacer),
  });
}

export async function analyze(file: File) {
  const metadata = await performFileUpload<{
    patientName: string
    acquisitionDate: string
    seriesDescription: string
  }>('volume/r/analyze/', file);

  return {
    patientName: metadata.patientName,
    acquisitionDate: new Date(metadata.acquisitionDate),
    seriesDescription: metadata.seriesDescription,
  };
}

export async function createExport(
  volumeId: Uuid,
  targetFileFormat: VolumeExportTargetFileFormat,
  targetBundleFormat: VolumeExportTargetBundleFormat | null,
) {
  return performInternalApiCall<{ downloadUrl: string }>(`volume/w/${volumeId}/create_export/`, {
    method: 'POST',
    payload: JSON.stringify({
      targetFileFormat,
      targetBundleFormat,
    }),
  });
}
