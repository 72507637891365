/**
 * Add a blob to the browser download file list.
 *
 * @param blob The blob containing the file data
 * @param filename The filename to display to the end-user
 */
export function promptFileDownload(blob: Blob, filename: string) {
  const file = new File([blob], filename);

  const fileURL = URL.createObjectURL(file);
  const link = document.createElement('a');
  link.href = fileURL;
  link.download = filename;

  link.click();
  link.remove();

  setTimeout(() => {
    URL.revokeObjectURL(fileURL);
  }, 500);
}

export function performFileDownload(url: string, filename: string, blank = false) {
  const link = document.createElement('a');
  link.download = filename;

  if (blank) {
    link.setAttribute('target', '_blank');
  }

  link.href = url;
  link.click();
  link.remove();
}
