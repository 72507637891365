
import Vue from 'vue';
import { required } from '@/validators';
import {
  VolumeExportTargetBundleFormat, VolumeExportTargetFileFormat, VOLUME_EXPORT_FILE_FORMATS, VOLUME_EXPORT_TARGET_BUNDLES,
} from '@/types';
import { mapGetters } from 'vuex';

interface IExportVolumeDialogData {
  isFormValid: boolean,
  formData: {
    fileFormat: VolumeExportTargetFileFormat | null,
    targetBundle: VolumeExportTargetBundleFormat | null
  },
  fileFormats: typeof VOLUME_EXPORT_FILE_FORMATS,
  targetBundles: typeof VOLUME_EXPORT_TARGET_BUNDLES
}

export interface IExportVolumeEventPayload {
  fileFormat: VolumeExportTargetFileFormat,
  bundleFormat: VolumeExportTargetBundleFormat | null
}

export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      required: false,
      default: () => null,
    },
    subtitle: {
      type: String,
      required: true,
    },
  },
  data: (): IExportVolumeDialogData => ({
    isFormValid: false,
    formData: {
      fileFormat: null,
      targetBundle: null,
    },
    targetBundles: VOLUME_EXPORT_TARGET_BUNDLES,
    fileFormats: VOLUME_EXPORT_FILE_FORMATS,
  }),
  computed: {
    requiresTargetBundle(): boolean {
      if (!this.formData.fileFormat) {
        return false;
      }

      return ['jpeg', 'png'].includes(this.formData.fileFormat);
    },
    ...mapGetters('tabs', [
      'currentVolume',
    ]),
    filteredFileFormats(): readonly string[] {
      // if current volume if NIFTI or VTI, remove NII from list
      if (['NIFTI', 'VTI'].includes(this.currentVolume?.data_format)) {
        return this.fileFormats.filter((format) => format !== 'nii');
      }

      return this.fileFormats;
    },
  },
  methods: {
    handleSaveClick() {
      this.$emit('save-clicked', {
        fileFormat: this.formData.fileFormat,
        bundleFormat: this.formData.targetBundle,
      });
      this.$emit('input', false);
    },
    required,
  },
});
