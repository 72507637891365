import Vue from 'vue';
import { performFileDownload } from '@/helpers/browser';
import {
  ConflictError, ForbiddenError, GoneError, NotFoundError, TooManyRequestError, UnauthorizedError, ValidationError,
} from '@/helpers/errors';
import { AuthorizationStatus, IAuthStoreState } from '@/store/auth.store';
import { IRootState } from '@/store/types';
import { Store } from 'vuex';
import { AUTH0_API_AUDIENCE } from '../../auth_config';

export const API_URL = process.env.VUE_APP_API_URL;

interface IApiRequestOptions {
  method?: 'POST' | 'PUT' | 'PATCH' | 'GET',
  payload?: string | FormData
  file?: boolean
  unauthenticated?: boolean
}

interface StoreBridge {
  store: Store<IRootState> | null
  getStore: () => Store<IRootState>
  getAuthState: () => IAuthStoreState
  getAuthorizationStatus: () => AuthorizationStatus
  setAuthorizationStatus: (status: AuthorizationStatus) => void
}

export const STORE_BRIDGE: StoreBridge = {
  store: null,
  getStore() {
    if (!this.store) {
      throw new Error('Store bridge not initiliazed');
    }

    return this.store;
  },
  getAuthState() {
    // @ts-expect-error hea
    return this.getStore().state.auth as IAuthStoreState;
  },
  getAuthorizationStatus() {
    return this.getAuthState().status;
  },
  setAuthorizationStatus(status: AuthorizationStatus) {
    this.getStore().commit('auth/SET_STATUS', status);
  },
};

/**
 * Perform an API call to the application backend.
 *
 * @param path the endpoint to be called
 * @param options
 * @returns
 */
export async function performInternalApiCall<T>(path: string, options?: IApiRequestOptions): Promise<T> {
  const requestInitOptions: RequestInit = {};
  const headers: Record<string, string> = {};

  if (!options || !options.unauthenticated) {
    const token = await Vue.prototype.$auth.getTokenSilently({
      authorizationParams: {
        audience: AUTH0_API_AUDIENCE,
        scope: 'openid',
      },
    });
    headers.Authorization = `Bearer ${token}`;
  }

  if (options) {
    requestInitOptions.method = options.method;
    requestInitOptions.body = options.payload;
  }

  requestInitOptions.credentials = 'include';

  const { accessType, shareLinkId, shareLinkType } = STORE_BRIDGE.getAuthState();

  if (accessType === 'share' && shareLinkId && shareLinkType) {
    headers['Share-Link-Id'] = shareLinkId;
    headers['Share-Link-Type'] = shareLinkType;
  }

  if (options && !options.file) {
    headers['Content-Type'] = 'application/json';
  }

  requestInitOptions.headers = headers;
  const response = await fetch(`${API_URL}/api/v1/${path}`, requestInitOptions);

  switch (response.status) {
    case 401: {
      if (STORE_BRIDGE.getAuthorizationStatus() !== AuthorizationStatus.UNKNOWN) {
        STORE_BRIDGE.setAuthorizationStatus(AuthorizationStatus.UNAUTHORIZED_INTERRUPTED);
      }

      throw new UnauthorizedError();
    }
    case 403: {
      const { detail } = await response.json();

      if (detail === 'ERR_INACTIVE') {
        STORE_BRIDGE.setAuthorizationStatus(AuthorizationStatus.INACTIVE);
      }

      throw new ForbiddenError(detail);
    }
    case 404:
      throw new NotFoundError();
    case 409: {
      const { details, message } = await response.json();
      throw new ConflictError(details, message);
    }
    case 410:
      throw new GoneError();
    case 422: {
      const { details, message } = await response.json();
      throw new ValidationError(details, message);
    }
    case 429:
      throw new TooManyRequestError();
    default:
  }

  return response.json();
}

export async function performApiFileDownload(path: string, filename: string, blank = false) {
  const fullPath = `${API_URL}/api/v1/${path}`;
  performFileDownload(fullPath, filename, blank);
}

/**
 * Starts a one file upload. This method uses FormData.
 *
 * @param path The API path where to upload the file
 * @param file The file to upload
 * @param data Additional form field to put into the form data
 */
export function performFileUpload<T>(path: string, file: File, data: Record<string, string> = {}) {
  const formData = new FormData();
  formData.append('file', file);

  Object.keys(data).forEach((key: string) => {
    formData.append(key, data[key]);
  });

  return performInternalApiCall<T>(path, {
    method: 'POST',
    payload: formData,
    file: true,
    unauthenticated: false,
  });
}
