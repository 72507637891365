
import { IFolder, IVolume, IStudy } from '@/api/item';
import { Uuid } from '@/types';
import Vue from 'vue';

interface ITreeViewItem {
  id?: Uuid
  name: string
  parentChain?: Array<{id: Uuid, name: string}>
  type: 'FOLDER' | 'VOLUME' | 'HEADER' | 'STUDY'
  modified: Date
}

const transformFolderContent = function transformFolderContent(
  volumes: IVolume[],
  folders: IFolder[],
  studies: IStudy[],
  activeStudyId: Uuid | null,
) {
  const volumeItems = volumes.map((volume): ITreeViewItem => ({
    id: volume.id,
    name: volume.name,
    type: 'VOLUME' as const,
    parentChain: volume.parentChain,
    modified: new Date(volume.modified),
  }));

  const studyItems = studies.map((study) => ({
    id: study.id,
    name: study.study_description || study.study_instance_uid,
    type: 'STUDY' as const,
    parentChain: study.parentChain,
    modified: new Date(study.modified),
    active: study.id === activeStudyId,
    children: study.volumes,
  }));

  const folderItems = folders.map((folder) => ({
    id: folder.id,
    name: folder.name,
    type: 'FOLDER' as const,
    parentChain: folder.parentChain,
    modified: new Date(folder.modified),
  }));

  // eslint-disable-next-line max-len
  return volumeItems.concat(folderItems, studyItems).sort((item1, item2) => (item1.modified.getTime() >= item2.modified.getTime() ? -1 : 1));
};

export default Vue.extend({
  props: {
    mode: {
      type: String as () => 'search' | 'browse',
      required: true,
    },
    volumes: {
      type: Array as () => IVolume[],
      required: true,
    },
    folders: {
      type: Array as () => IFolder[],
      required: true,
    },
    studies: {
      type: Array as () => IStudy[],
      required: true,
    },
    activeStudyId: {
      type: String as () => Uuid,
      required: false,
      default: null,
    },
  },
  computed: {
    treeItems(): ITreeViewItem[] {
      return transformFolderContent(this.volumes, this.folders, this.studies, this.activeStudyId);
    },
  },
  methods: {
    handleItemClick(item: ITreeViewItem) {
      const eventName = `${item.type.toLowerCase()}-clicked`;
      this.$emit(eventName, item.id);
    },
  },
});
