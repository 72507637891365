import type { IVolume } from '@/api/item';
import { getRandomString } from '@/helpers/str';
import { Uuid } from '@/types';
import { Module } from 'vuex';
import { IRootState } from './types';

export type TabStatus = 'LOADING' | 'UPLOADING' | 'EMPTY' | 'LOADED'

export interface ITabItem {
  volume: IVolume | null // maybe null in case of empty tab
  get name(): string // will be the volume name in most cases
  status: TabStatus
  id: string
}

interface ITabsStoreState {
  tabItems: ITabItem[]
  openedVolumeIds: Uuid[]
  activeTabId: string | null
}

const createTab = function createTab(volume: IVolume | null = null): ITabItem {
  return {
    volume,
    get name() {
      return this.volume ? this.volume.name : 'empty';
    },
    status: volume ? 'LOADING' : 'EMPTY',
    id: Date.now() + getRandomString(),
  };
};

const defaultTab = createTab();

export default {
  state: {
    openedVolumeIds: [],
    tabItems: [defaultTab],
    activeTabId: defaultTab.id,
  },
  getters: {
    activeTab(state) {
      return state.activeTabId != null ? state.tabItems.find((tabItem) => tabItem.id === state.activeTabId) : null;
    },
    tabVolume: (state) => (volumeId: Uuid) => state.tabItems.find(({ volume }) => (volume?.id === volumeId)),
    currentVolume(state, getters) {
      const { activeTab } = getters;

      if (!activeTab || !activeTab.volume) {
        return null;
      }

      return activeTab.volume;
    },
  },
  mutations: {
    ADD_TAB(state, { volume, activate = true }: { volume: IVolume, activate: boolean }) {
      const newTabItem = createTab(volume);
      state.tabItems.push(newTabItem);

      if (activate) {
        state.activeTabId = newTabItem.id;
      }
    },
    SET_ACTIVE_TAB(state, tabId: string | undefined) {
      state.activeTabId = tabId == null ? null : tabId;
    },
    LINK_TAB_TO_VOLUME(state, { tabItem, volume }: { tabItem: ITabItem, volume: IVolume}) {
      const currentVolumeId = tabItem.volume?.id;
      // unload the previous volume if relevant
      if (currentVolumeId) {
        state.openedVolumeIds.splice(state.openedVolumeIds.indexOf(currentVolumeId), 1);
      }

      // eslint-disable-next-line no-param-reassign
      tabItem.volume = volume;
    },
    REMOVE_TAB(state, tabId: string) {
      const tabItem = state.tabItems.find((tab) => tab.id === tabId);

      if (!tabItem) {
        return;
      }
      const tabIdx = state.tabItems.indexOf(tabItem);
      // remove the currently active tab
      const closedTabs = state.tabItems.splice(tabIdx, 1);

      const closedTabVolumeId = closedTabs[0].volume?.id;
      // If a volume was opened in the tab we just closed, delete it
      if (closedTabVolumeId) {
        state.openedVolumeIds.splice(state.openedVolumeIds.indexOf(closedTabVolumeId), 1);
      }
    },
    ADD_OPENED_VOLUME(state, volumeId: Uuid) {
      state.openedVolumeIds.push(volumeId);
    },
    UPDATE_TAB_STATUS(_state, { tabItem, status }: { tabItem: ITabItem, status: TabStatus }) {
      // eslint-disable-next-line no-param-reassign
      tabItem.status = status;
    },
  },
  actions: {
    openVolume({ commit }, { volume, tabItem }: { volume: IVolume, tabItem: ITabItem | null }) {
      if (tabItem) {
        commit('LINK_TAB_TO_VOLUME', {
          volume,
          tabItem,
        });
        commit('SET_ACTIVE_TAB', tabItem.id);
      } else {
        commit('ADD_TAB', { volume, activate: true });
      }
      commit('ADD_OPENED_VOLUME', volume.id);
    },
    removeCurrentTab({ commit, state }) {
      if (state.activeTabId != null) {
        commit('REMOVE_TAB', state.activeTabId);
      }
    },
  },
  namespaced: true,
} as Module<ITabsStoreState, IRootState>;
