
import Vue from 'vue';
// @ts-expect-error no declaration file is available
import QRious from 'qrious';
import CopyBtn from '../util/CopyBtn.vue';

interface IShareLinkDialogData {
  permission: 'Viewer' | 'Editor'
  qr: null | { value: string }
}

export default Vue.extend({
  components: { CopyBtn },
  props: {
    value: {
      type: Boolean,
      required: false,
      default: () => null,
    },
    type: {
      type: String as () => 'volume' | 'folder',
      required: true,
    },
    itemName: {
      type: String,
      required: false,
      default: () => '',
    },
    link: {
      type: String,
      required: true,
    },
  },
  data: (): IShareLinkDialogData => ({
    permission: 'Viewer',
    qr: null,
  }),
  computed: {
    title() {
      return `Get ${this.type} link`;
    },
  },
  watch: {
    link(newLink: string) {
      if (this.qr) {
        this.qr.value = newLink;
      }
    },
  },
  mounted() {
    this.qr = new QRious({
      element: document.getElementById('qr-code'),
      value: this.link,
      level: 'H',
      padding: 25,
      size: 200,
    });
  },
});
