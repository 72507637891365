
import Vue from 'vue';

export default Vue.extend({
  props: {
    textToCopy: {
      type: String,
      required: false,
      default: () => '',
    },
  },
  methods: {
    copyToClipboard() {
      navigator.clipboard.writeText(this.textToCopy);
    },
  },
});
