
import { IFolder } from '@/api/item';
import Vue from 'vue';
import { mapState } from 'vuex';

export default Vue.extend({
  props: {
    folderChain: {
      type: Array as () => IFolder[],
      required: true,
    },
    disabled: {
      type: Boolean,
    },
  },
  computed: {
    ...mapState('treeview', [
      'rootFolderId',
    ]),
  },
});
