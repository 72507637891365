
import Vue from 'vue';
import SessionSlotsTable from '@/components/SessionSlotsTable.vue';
import { all, acquire } from '@/api/session_slot';
import { Uuid } from '@/types';

interface INoAvailableSlotViewData {
  sessionSlots: unknown[]
}
export default Vue.extend({
  components: {
    SessionSlotsTable,
  },
  data(): INoAvailableSlotViewData {
    return {
      sessionSlots: [],
    };
  },
  async mounted() {
    this.sessionSlots = await all();
  },
  methods: {
    async acquireSlot({ id }: { id: Uuid}) {
      await acquire(id);
      window.location.reload();
    },
  },
});
