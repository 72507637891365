import Vue from 'vue';
import { createAuth0Client, Auth0Client } from '@auth0/auth0-spa-js';

export interface IData {
  auth0Client: Auth0Client | null
  loading: boolean
  isAuthenticated: boolean
  popupOpen: boolean
  user: object
  error: null
}

/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname);

// @ts-expect-error auth
let instance;

/** Returns the current instance of the SDK */
// @ts-expect-error auth
export const getInstance = () => instance;

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  ...options
}) => {
  // @ts-expect-error auth
  if (instance) return instance;

  // The 'instance' is simply a Vue object
  instance = new Vue({
    data(): IData {
      return {
        loading: true,
        isAuthenticated: false,
        user: {},
        auth0Client: null,
        popupOpen: false,
        error: null,
      };
    },
    /** Use this lifecycle method to instantiate the SDK client */
    async created() {
      // Create a new instance of the SDK client using members of the given options object
      // @ts-expect-error auth
      this.auth0Client = await createAuth0Client({
        ...options,
        clientId: options.clientId,
        authorizationParams: {
          redirect_uri: redirectUri,
          scope: 'openid',
          ...options.authorizationParams,
        },
      });

      try {
        // If the user is returning to the app after authentication..
        if (
          window.location.search.includes('code=')
          && window.location.search.includes('state=')
        ) {
          // handle the redirect and retrieve tokens
          const { appState } = await this.auth0Client.handleRedirectCallback();

          this.error = null;

          // Notify subscribers that the redirect callback has happened, passing the appState
          // (useful for retrieving any pre-authentication state)
          // @ts-expect-error auth
          onRedirectCallback(appState);
        }
      } catch (e) {
        this.error = e;
      } finally {
        // Initialize our internal authentication state
        this.isAuthenticated = await this.auth0Client.isAuthenticated();

        this.user = await this.auth0Client.getUser();
        this.loading = false;
      }
    },
    methods: {
      /** Handles the callback when logging in using a redirect */
      async handleRedirectCallback() {
        this.loading = true;
        try {
          await this.auth0Client.handleRedirectCallback();
          this.user = await this.auth0Client.getUser();
          this.isAuthenticated = true;
          this.error = null;
        } catch (e) {
          this.error = e;
        } finally {
          this.loading = false;
        }
      },
      /** Authenticates the user using the redirect method */
      loginWithRedirect(o: unknown) {
        return this.auth0Client.loginWithRedirect({
          // @ts-expect-error test
          ...o,
          redirectUri: window.location.origin,
        });
      },
      /** Returns all the claims present in the ID token */
      getIdTokenClaims(o: unknown) {
        return this.auth0Client.getIdTokenClaims(o);
      },
      /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
      getTokenSilently(o: Record<string, unknown>) {
        return this.auth0Client.getTokenSilently({
          ...o,
        });
      },
      /** Gets the access token using a popup window */

      getTokenWithPopup(o: unknown) {
        return this.auth0Client.getTokenWithPopup(o);
      },
      /** Logs the user out and removes their session on the authorization server */
      logout(o: unknown) {
        return this.auth0Client.logout(o);
      },
    },
  });

  return instance;
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const Auth0Plugin = {
  install(VueGlobal: typeof Vue, options: Record<string, string>) {
    // eslint-disable-next-line no-param-reassign
    VueGlobal.prototype.$auth = useAuth0(options);
  },
};

export const NoAuthPlugin = {
  install(VueGlobal: typeof Vue) {
    // eslint-disable-next-line no-param-reassign
    VueGlobal.prototype.$auth = (function useNoAuthPlugin() {
      // @ts-expect-error auth
      if (instance) return instance;

      instance = new Vue({
        data() {
          return {
            isAuthenticated: true,
          };
        },
        methods: {
          loginWithRedirect() {
            return Promise.resolve();
          },
          getTokenSilently() {
            return Promise.resolve();
          },
        },
      });

      return instance;
    }());
  },
};
