import { Module } from 'vuex';
import { IRootState } from './types';

interface ITasksStoreState {
  exportInProgress: boolean
}

export default {
  state: {
    exportInProgress: false,
  },
  getters: {},
  mutations: {
    SET_EXPORT_IN_PROGRESS(state, exportInProgress: boolean) {
      state.exportInProgress = exportInProgress;
    },
  },
  actions: {},
  namespaced: true,
} as Module<ITasksStoreState, IRootState>;
