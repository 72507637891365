import { IUploadableVolume } from '@/api/item';
import { update } from '@/api/volume';
import { Module } from 'vuex';
import { chunk } from '@/helpers/utils';
import { IRootState } from './types';

type UploadStatus = 'CHECKING' | 'UPLOADING' | 'STALE'

interface IUploadStoreState {
  status: UploadStatus
  filename: string | null
}

export default {
  state: {
    status: 'STALE',
    filename: null,
  },
  getters: {
    inProgress: (state) => ['CHECKING', 'UPLOADING'].includes(state.status),
  },
  actions: {
    async uploadVolumeData(
      { commit },
      { volume, maxNumberOfQueuedFileUpload }: { volume: IUploadableVolume, maxNumberOfQueuedFileUpload: number },
    ) {
      commit('ui/SHOW_SNACKBAR', { type: 'info', message: 'Volume data upload started' }, { root: true });

      try {
        for (const files of chunk(volume.fileList, maxNumberOfQueuedFileUpload)) {
          const payloadPromises = files.map(async (file) => {
            const uploadUrl = volume.uploadUrls[file.name];

            return fetch(uploadUrl, {
              method: 'PUT',
              body: await file.arrayBuffer(),
            }).then((response) => {
              // Return a rejected promise if the upload request failed
              if (!response.ok) {
                return Promise.reject(new Error(`Error uploading volume data file: ${file.name}`));
              }
            });
          });

          // eslint-disable-next-line no-await-in-loop
          await Promise.all(payloadPromises);
        }

        const uploadedVolume = await update({
          id: volume.id,
          draft: false,
        });

        commit('treeview/ADD_OR_UPDATE_VOLUME', uploadedVolume, { root: true });
        commit('ui/SHOW_SNACKBAR', { type: 'success', message: 'Volume data uploaded successfuly!' }, { root: true });
      } catch (e) {
        commit('ui/SHOW_SNACKBAR', { type: 'error', message: 'Volume data upload failed!' }, { root: true });
        throw e;
      }
    },
  },
  mutations: {
    UPDATE_UPLOAD_STATUS(state, status: UploadStatus) {
      state.status = status;
    },
    UPDATE_UPLOAD_NAME(state, name: string) {
      state.filename = name;
    },
  },
  namespaced: true,
} as Module<IUploadStoreState, IRootState>;
