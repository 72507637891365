
import Vue from 'vue';
import {
  required, alphaNum, makeNotIn, ValidatorFunc,
} from '@/validators';
import FolderChainBreadcrumb from '@/components/util/FolderChainBreadcrumb.vue';
import { IFolderWithContent, IVolume } from '@/api/item';
import { mapActions, mapGetters } from 'vuex';
import { Uuid } from '@/types';
import ItemBrowser from '@/components/util/ItemBrowser.vue';

const defaultValidators = [required, alphaNum];

interface IExportVolumeDialogData {
  isFormValid: boolean,
  formData: {
    name: string | null,
    folder: IFolderWithContent | null
  },
  volumeParentFolder: null | IFolderWithContent,
  fieldErrors: {
    name: boolean
  }
  menu: boolean
}

export default Vue.extend({
  components: { FolderChainBreadcrumb, ItemBrowser },
  props: {
    value: {
      type: Boolean,
      required: false,
      default: () => null,
    },
    volume: {
      type: Object as () => IVolume,
      required: true,
    },
    errors: {
      type: Object as () => Record<string, string[]>,
      required: false,
      default: () => ({}),
    },
  },
  data: (): IExportVolumeDialogData => ({
    isFormValid: false,
    volumeParentFolder: null,
    formData: {
      name: null,
      folder: null,
    },
    fieldErrors: {
      name: false,
    },
    menu: false,
  }),
  computed: {
    nameBlacklist() {
      if (!this.formData.folder) {
        return [];
      }
      const volumes: IVolume[] = this.folderVolumes(this.formData.folder.id);
      return volumes.map((volume) => volume.name);
    },

    validators(): ValidatorFunc[] {
      const val: ValidatorFunc[] = [...defaultValidators];

      val.push(
        makeNotIn(this.nameBlacklist, 'This name is already taken in the selected destination folder.'),
      );

      return val;
    },
    sourceFullChain() {
      if (this.volumeParentFolder && this.volumeParentFolder.parentChain) {
        return [...this.volumeParentFolder.parentChain, this.volume];
      }
      return [];
    },
    targetFolderChain() {
      if (this.formData.folder && this.formData.folder.parentChain) {
        return [
          ...this.formData.folder.parentChain,
          { name: this.formData.folder.name, id: this.formData.folder.id },
        ];
      }
      return [];
    },
    ...mapGetters('treeview', [
      'folderVolumes',
    ]),
  },
  watch: {
    volume: {
      async handler(volume: IVolume) {
        this.volumeParentFolder = await this.getFolder({ folderId: volume.folder });
        this.formData.folder = this.volumeParentFolder;
      },
      immediate: true,
    },
    validators() {
      // validate manually because vuetify won't eagerly revalidate after a validator update
      // perform the validation after next tick because there's a chance that v-text-field did not
      // update its validators yet in the current tick
      this.$nextTick(() => {
        // @ts-expect-error saveAsForm should exists
        this.$refs.saveAsForm.validate();
      });
    },
  },
  methods: {
    ...mapActions('treeview', [
      'getFolder',
      'loadFolder',
    ]),
    handleSaveClick() {
      this.$emit('save-clicked', {
        name: this.formData.name,
        folder: this.formData.folder,
      });
    },
    async handleFolderClicked(folderId: Uuid) {
      this.formData.folder = await this.getFolder({ folderId, activate: false });
    },
  },
});
