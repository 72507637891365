import { SupportedExtension } from '@/api/volume';
import { extractFilenameFromUrl } from '@/helpers/url';
import { Store } from 'vuex';
import { IViewerState, IViewerStateUserData, ViewerApp } from '.';

const STATE_VERSION = 2;

interface ICornerAnnotation {
  getAnnotationContainer: () => HTMLElement;
}

interface IView {
  activate: () => void
  getProxyName: () => string
  getContainer: () => unknown
  captureImage: () => Promise<string>
  getCornerAnnotation: () => ICornerAnnotation
}

interface ISource {
  getProxyId: () => string
  getDataset: () => object
  activate: () => void
  setKey: (arg1: unknown, arg2: unknown) => void
}

type onActiveSourceChangeCb = (source?: ISource) => void

interface IProxyManager {
  getProxyById: (id: string | null) => ISource
  onActiveSourceChange: (cb: onActiveSourceChangeCb) => void
  deleteProxy: (proxy: unknown) => void
  saveState: (options: unknown, userData: unknown) => IViewerState;
  loadState: (state: IViewerState, options: { datasetHandler: () => void }) => Promise<IViewerStateUserData>
  getSources: () => ISource[]
  modified: () => void
  getViews: () => IView[]
}

interface IGlanceState {
  proxyManager: IProxyManager
  files: {
    fileList: Array<unknown>
  }
  views: {
    viewOrder: string[]
    visibleCount: number
  }
  route: unknown
  widgets: unknown
}

interface IGlanceWindow extends Window {
  $glanceStore: Store<IGlanceState>
  createRepresentationInAllViews: (pxm: IProxyManager, source: ISource) => void
  merge: (state: unknown, store: unknown) => IGlanceState
}

export default class GlanceApp implements ViewerApp {
  iframe: HTMLIFrameElement

  #window: IGlanceWindow

  #activeSourceId: string | null

  #store: Store<IGlanceState>

  #proxyManager: IProxyManager

  #hasLoadedVolume = false

  /**
   *
   * @param iframe The iframe must be loaded
   */
  constructor(iframe: HTMLIFrameElement) {
    this.iframe = iframe;
    // Glance is supposed to expose its store via the $glanceStore window property
    this.#window = (this.iframe.contentWindow as IGlanceWindow);
    this.#store = this.#window.$glanceStore as Store<IGlanceState>;
    this.#activeSourceId = null;
    this.#proxyManager = this.#store.state.proxyManager;

    this.#proxyManager.onActiveSourceChange((source) => {
      if (!source) {
        return;
      }

      this.#activeSourceId = source.getProxyId();
    });

    this.#store.dispatch('views/splitView', 0).then(() => this.#store.dispatch('views/quadView'));

    this.#proxyManager.getViews().forEach((view) => {
      const annotationContainer = view.getCornerAnnotation().getAnnotationContainer();
      annotationContainer.style.fontSize = '10pt';
    });
  }

  async set2DBackgrounds(color: string) {
    // For each 2D view, set the background color
    const viewTypes: string[] = Object.values(this.#store.state.views.viewOrder);
    await Promise.all(viewTypes
      .filter((viewType) => viewType.startsWith('View2D'))
      .map((viewType) => this.#store.dispatch('views/changeBackground', { viewType, color })));
  }

  async unloadVolume() {
    // Do we have a loaded file?
    const fileExist = this.#store.state.files.fileList.length !== 0;

    if (fileExist) {
      if (this.#activeSourceId) {
        // Remove the volume from the views
        const proxy = this.#proxyManager.getProxyById(this.#activeSourceId);
        if (proxy) {
          await this.#proxyManager.deleteProxy(proxy);
        }
      }
      await this.#store.dispatch('files/deleteFile', 0);
    }
  }

  /**
   * Download the files from the given URL's and open them as a volume in glance.
   * Example URL: http://172.22.0.2:9000/crystallizer-organization-data-ag8gdkct/patients/john_doe/05072022/00001/coronacases_org_002_crop.nii?<AWS_OPTIONS...>
   *
   * @param urls Array of URL's pointing to AWS/Minio objects
   */
  async openRemoteVolume(urls: string[], state?: IViewerState) {
    await this.unloadVolume();

    if (state) {
      await this.#openVolumeWithState(urls, state);
    } else {
      if (urls.length === 1) {
        await this.#openSingleFileVolume(urls[0]);
      } else {
        await this.#openMultiFileVolume(urls);
      }
      await this.#store.dispatch('files/load');
    }
    this.#hasLoadedVolume = true;
  }

  async openLocalVolume(files: File[], extensions: SupportedExtension[]) {
    let extension = extensions[0];

    // Glance does not support .dicom extension, so use make sure we always use .dcm
    if (extension === 'dicom') {
      extension = 'dcm';
    }

    await this.unloadVolume();
    await this.#store.dispatch('files/openFiles', files.map((file) => {
      // For local file names, we must provide the correct extension if it is missing
      const filename = file.name.endsWith(`.${extension}`) ? file.name : `${file.name}.${extension}`;
      // @ts-expect-error recreate the files using the iframe Window File constructor
      return new this.#window.File([file], filename);
    }));
    await this.#store.dispatch('files/load');
    this.#hasLoadedVolume = true;
  }

  async #openSingleFileVolume(url: string) {
    const name = extractFilenameFromUrl(url);

    return this.#store.dispatch('files/openRemoteFiles', [
      {
        name,
        url,
      },
    ]);
  }

  async #openMultiFileVolume(urls: string[]) {
    // Fetch all the files in parallel
    const responses = await Promise.all(urls.map((url) => fetch(url)));

    // Read all the downloaded files as blobs
    const blobPromises = responses.map(async (response) => ({
      blob: await response.blob(),
      filename: extractFilenameFromUrl(response.url),
    }));

    const blobList = await Promise.all(blobPromises);

    // Glance (and under the hood itk.wasm) expects an array of File
    // So, construct a File array from the blob list
    // On Chrome (and I believe chromium-based browsers but did not test it), there is an issue with promise-file-reader
    // https://github.com/jahredhope/promise-file-reader/blob/master/PromiseFileReader.js#L2
    // this line would fail because we created the File instances in a different window, so the instanceof operator would
    // not return what's expected. I believe the firefox team foresaw this issue.
    // @ts-expect-error use the iframe Window File object to create the files
    const files = blobList.map(({ blob, filename }) => new this.#window.File([blob], filename, { type: blob.type }));

    return this.#store.dispatch('files/openFiles', files);
  }

  async #openVolumeWithState(urls: string[], appState : IViewerState) {
    if (appState.sources.length === 0) {
      throw new Error('Cannot load dataset: the provided state has no source.');
    }

    for (let i = 0; i < appState.sources.length; i += 1) {
      const source = appState.sources[i];
      if (source.props.dataset.name?.endsWith('.dcm')) {
        source.props.dataset.seriesUrls = urls;
      } else {
        const url = urls.find((x: string) => extractFilenameFromUrl(x) === source.props.name) || '';
        source.props.dataset.url = url;
      }
    }
    return this.#store.dispatch('restoreAppState', appState);
  }

  async getCurrentState() {
    if (!this.#hasLoadedVolume) {
      throw new Error('Cannot fetch viewer state: no volume is currently loaded.');
    }

    const userData = {
      version: STATE_VERSION,
      activeSourceId: this.#activeSourceId,
      store: {
        route: this.#store.state.route,
        views: this.#store.state.views,
        widgets: this.#store.state.widgets,
      },
    };

    const options = {
      recycleViews: true,
      datasetHandler(dataset: any, source: any) {
        const sourceMeta = source.get('name');
        const datasetMeta = dataset.get('name');
        const metadata = sourceMeta.name ? sourceMeta : datasetMeta;
        // Overwrite serializedType to 'crystallizer' so that Glance won't
        // overwrite the url and try to download the data from Girder.
        metadata.serializedType = 'crystallizer';

        return metadata;
      },
    };

    return this.#proxyManager.saveState(options, userData);
  }

  getHasLoadedVolume() {
    return this.#hasLoadedVolume;
  }

  /**
   * Take a screenshot of each visible view
   *
   * @returns an array of images data URL's
   */
  takeScreenshots(): Promise<string[]> {
    const views = this.#proxyManager.getViews();
    const { visibleCount } = this.#store.state.views;
    const visibleViewIndexes = this.#store.state.views.viewOrder.slice(0, visibleCount);
    const visibleViewProxyNames = visibleViewIndexes.map((viewIdx: string) => viewIdx.split(':')[0]);
    const visibleViews = views.filter((view) => visibleViewProxyNames.includes(view.getProxyName()));

    return Promise.all(visibleViews.map((view) => view.captureImage()));
  }

  openDrawerPanel() {
    const unsubscribe = this.#store.subscribeAction((actionPayload) => {
      if (actionPayload.type === 'views/onResizedAllViews') {
        this.#store.commit('toggleInternalControlsDrawer', true);
        unsubscribe();
      }
    });
  }
}
