import JSZip from 'jszip';

export interface IFileHandle {
  filename: string
  blob: Blob
}

/**
 * Create a zip archive with the given file set.
 * Wrap the files in a folder.
 *
 * @param name Name of the archive
 * @param fileSet The files to put in the archive
 * @returns The archive file handle
 */
export async function createArchive(name: string, fileSet: IFileHandle[]): Promise<IFileHandle> {
  const zip = new JSZip();

  for (const { blob, filename } of fileSet) {
    zip.file(`${name}/${filename}`, blob);
  }

  return {
    blob: await zip.generateAsync({ type: 'blob' }),
    filename: `${name}.zip`,
  };
}
