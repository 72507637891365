
import Vue from 'vue';
import DataManagementPanel from '@/components/layout/DataManagementPanel.vue';
import { Uuid } from '@/types';
import ChooseNameDialog from '@/components/dialogs/ChooseNameDialog.vue';
import ExportVolumeDialog, { IExportVolumeEventPayload } from '@/components/dialogs/ExportVolumeDialog.vue';
import ShareLinkDialog from '@/components/dialogs/ShareLinkDialog.vue';
import SaveAsDialog from '@/components/dialogs/SaveAsDialog.vue';
import MenuBar from '@/components/layout/MenuBar.vue';
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import { downloadVolumeData } from '@/helpers/volume.helper';
import { performFileDownload, promptFileDownload } from '@/helpers/browser';
import { createArchive } from '@/helpers/zip';
import {
  share, ILoadableVolume, IUploadableVolume, IFolder, IVolume,
} from '@/api/item';
import { viewerApps } from '@/viewer_app';
import { ITabItem } from '@/store/tabs.store';
import { create as createVolumeState } from '@/api/volume_state';
import TabSystem from '@/components/layout/TabSystem.vue';
import { create, createExport } from '@/api/volume';

interface IMainWebAppData {
  dialogs: Record<string, {
    visible: boolean,
    [key: string]: unknown
  }>
}

export default Vue.extend({
  components: {
    DataManagementPanel, MenuBar, ChooseNameDialog, ShareLinkDialog, ExportVolumeDialog, TabSystem, SaveAsDialog,
  },

  data: (): IMainWebAppData => ({
    dialogs: {
      chooseName: {
        visible: false,
        blacklist: [],
      },
      shareLink: {
        visible: false,
        itemType: null,
        itemName: undefined,
        link: undefined,
      },
      exportVolume: {
        visible: false,
        subtitle: '',
      },
      saveAs: {
        visible: false,
        volume: null,
      },
    },
  }),

  computed: {
    ...mapState('treeview', [
      'volumes',
    ]),
    ...mapGetters('tabs', [
      'activeTab',
      'currentVolume',
      'tabVolume',
    ]),
    ...mapGetters('treeview', [
      'isVolumeInCurrentFolder',
      'folderVolumes',
      'currentFolderIsRoot',
      'currentFolder',
    ]),
    ...mapGetters('auth', [
      'isAuthenticated',
      'sharedFolder',
    ]),
  },

  methods: {
    ...mapMutations('tabs', {
      setActiveTab: 'SET_ACTIVE_TAB',
      addTab: 'ADD_TAB',
    }),
    ...mapMutations('treeview', {
      addOrUpdateVolume: 'ADD_OR_UPDATE_VOLUME',
    }),
    ...mapMutations('ui', {
      showSnackbar: 'SHOW_SNACKBAR',
      updatePromptBeforeLeave: 'UPDATE_PROMPT_BEFORE_LEAVE',
    }),
    ...mapActions('tabs', [
      'openVolume',
      'removeCurrentTab',
    ]),
    ...mapActions('treeview', [
      'loadVolume',
      'loadFolder',
      'updateVolume',
    ]),
    ...mapMutations('tasks', {
      setExportInProgress: 'SET_EXPORT_IN_PROGRESS',
    }),

    /**
     * Handle clicked volume event. If the volume is already opened in a tab, find the tab and make it active.
     * If the volume is not opened, load and open it.
     *
     * @param id volume id
     */
    async handleVolumeClicked(id: Uuid) {
      const volumeTabItem = this.tabVolume(id);

      if (volumeTabItem) {
        this.setActiveTab(volumeTabItem.id);
      } else {
        const volume = await this.loadVolume({
          volumeId: id,
          signedUrl: true,
          state: true,
        });
        this.openVolume({ volume, tabItem: this.activeTab });
      }
    },

    handleVolumeUploadStarting({ volumes, tabItem }: { volumes: IUploadableVolume[], tabItem?: ITabItem }) {
      volumes.forEach(this.addOrUpdateVolume);

      if (volumes.length === 1) {
        this.openVolume({ volume: volumes[0], tabItem: tabItem ?? this.activeTab });
      }
    },

    /**
     * Handle completed volume upload.
     *
     * @param volume the uploaded volume
     * @param tabItem optional - will be defined if user uploaded from a tab
     */
    handleVolumeUploadCompleted({ volumes, tabItem }: { volumes: ILoadableVolume[], tabItem?: ITabItem }) {
      volumes.forEach(this.addOrUpdateVolume);

      if (volumes.length === 1) {
        this.openVolume({ volume: volumes[0], tabItem: tabItem ?? this.activeTab });
      } else {
        // if multiple volumes, there is also a study to load
        const volume = volumes[0];
        const studyId = volume.study;
        this.loadFolder({ folderId: volume.folder, studyId, activate: true });
      }
    },

    /**
     * React to the rename volume event. The renamed volume is the currently active one.
     * Open a dialog to prompt the user for the volume new name.
     */
    async handleRenameClicked() {
      let folderLoaded = Promise.resolve();

      if (!this.isVolumeInCurrentFolder(this.currentVolume.id)) {
        // load the volume parent folder, but do not make it active
        folderLoaded = this.loadFolder({
          folderId: this.currentVolume.folder,
          activate: false,
        });
      }

      this.dialogs.chooseName.visible = true;

      folderLoaded.then(() => {
        this.dialogs.chooseName.blacklist = this.folderVolumes(this.currentVolume.folder)
          .filter(({ id }: { id: Uuid }) => this.currentVolume.id !== id)
          .map((volume: IVolume) => volume.name);
      });
    },

    async handleRenameVolumeClicked({ name }: { name: string }) {
      const volumeId = this.currentVolume.id;
      this.updateVolume({ id: volumeId, name });
      this.dialogs.chooseName.visible = false;
    },

    /**
     * React to the download event. Download the raw volume data of the active tabulation.
     */
    async handleDownloadClicked() {
      // Make the volume loadable (by requesting file urls) if it is not
      if (!this.currentVolume.fileUrls) {
        await this.loadVolume({ volumeId: this.currentVolume.id, signedUrl: true });
      }

      const fileSet = await downloadVolumeData(this.currentVolume);

      // If more than one files we'll create a zip archive of them
      const downloadFile = fileSet.length > 1
        ? await createArchive(this.currentVolume.name, fileSet)
        : { filename: this.currentVolume.name, blob: fileSet[0].blob };

      promptFileDownload(downloadFile.blob, downloadFile.filename);
    },

    openShareLinkDialog(type: 'volume' | 'folder', name: string, link: string) {
      this.dialogs.shareLink.itemType = type;
      this.dialogs.shareLink.itemName = name;
      this.dialogs.shareLink.link = window.location.origin + link;
      this.dialogs.shareLink.visible = true;
    },

    async handleShareActiveVolumeClicked() {
      const viewerApp = viewerApps.get(this.activeTab);

      if (!viewerApp) {
        return;
      }

      const viewerState = viewerApp.getHasLoadedVolume() ? await viewerApp.getCurrentState() : undefined;

      // Get the link pointing to the current volume
      const { link } = await share('volume', this.currentVolume.id, viewerState);

      this.openShareLinkDialog('volume', this.currentVolume.name, link);
    },

    async handleShareCurrentFolderClicked() {
      // Get the link pointing to the currently opened folder
      const { link } = await share('folder', this.currentFolder.id);

      this.openShareLinkDialog('folder', this.currentFolder.name, link);
    },
    async handleSaveClicked() {
      const viewerApp = viewerApps.get(this.activeTab);

      if (viewerApp) {
        // TODO: handle saving segmentations made by painting on the volume (how?)
        const state = await viewerApp?.getCurrentState();

        try {
          await createVolumeState(this.currentVolume.id, state);
          this.showSnackbar({ message: 'Successfully saved volume state!', type: 'success' });
        } catch (e) {
          this.showSnackbar({ message: 'Failure saving volume state!', type: 'error' });
        }
      }
    },
    openExportVolumeDialog() {
      this.dialogs.exportVolume.subtitle = this.currentVolume.name;
      this.dialogs.exportVolume.visible = true;
    },
    async handleExportVolumeClicked({ fileFormat, bundleFormat }: IExportVolumeEventPayload) {
      try {
        this.setExportInProgress(true);
        this.updatePromptBeforeLeave(true);
        this.showSnackbar({ message: 'Export started, please wait...', type: 'info' });
        const { downloadUrl } = await createExport(this.currentVolume.id, fileFormat, bundleFormat);
        this.showSnackbar({ message: 'Export successful!', type: 'success' });
        performFileDownload(downloadUrl, this.currentVolume.name, bundleFormat === 'pdf');
      } catch (e) {
        this.showSnackbar({ message: 'Failure at exporting!', type: 'error' });
      } finally {
        this.updatePromptBeforeLeave(false);
        this.setExportInProgress(false);
      }
    },
    async handlePrintClicked() {
      const viewerApp = viewerApps.get(this.activeTab);

      if (!viewerApp) {
        return;
      }

      const imgSrcs = await viewerApp.takeScreenshots();

      const images = imgSrcs.map((imgSrc) => {
        const image = new Image();
        image.src = imgSrc;
        return image;
      });

      // w could be null because the browser can block this action
      // but since we're doing it directly after a user action, no browser should block it
      const w = window.open('');

      if (w) {
        // Display the images vertically in a new tab
        w.document.write(
          '<div style="display: flex; flex-direction: column; align-items: center;">',
          ...images.map((image) => image.outerHTML),
          '</div>',
        );
      } else {
        this.showSnackbar({ message: 'Failed to open a new tab!', type: 'error' });
      }
    },
    handleSaveAsClicked() {
      this.dialogs.saveAs.volume = this.currentVolume;
      this.dialogs.saveAs.visible = true;
    },
    async performSaveAs({ name, folder }: { name: string, folder: IFolder }) {
      const volume = this.currentVolume;
      this.dialogs.saveAs.visible = false;
      try {
        this.showSnackbar({ message: 'Saving new volume...', type: 'info' });
        await create({ name, container: folder.id }, volume.id);

        if (this.currentFolder.id === folder.id) {
          this.loadFolder({ folderId: folder.id });
        }

        this.showSnackbar({ message: 'Volume successfuly saved!', type: 'success' });
      } catch (e) {
        this.showSnackbar({ message: 'Failed to save new volume', type: 'error' });
      }
    },
  },
});
