
import { Uuid } from '@/types';
import UploadControl from '@/components/util/UploadControl.vue';
import ItemBrowser from '@/components/util/ItemBrowser.vue';
import Vue from 'vue';
import debounce from 'lodash.debounce';
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import { IVolume, IFolder, IStudy } from '@/api/item';
import {
  catchError, DetailedError,
} from '@/helpers/errors';
import FolderChainBreadcrumb from '../util/FolderChainBreadcrumb.vue';
import ChooseNameDialog from '../dialogs/ChooseNameDialog.vue';

interface IDataManagementPanelData {
  searchQuery: string | null
  dialogs: {
    chooseName: boolean
    errors: Record<string, string[]> | undefined
  }
  drawerWidth: string
}

export default Vue.extend({
  components: {
    UploadControl, FolderChainBreadcrumb, ChooseNameDialog, ItemBrowser,
  },
  data: (): IDataManagementPanelData => ({
    searchQuery: '',
    dialogs: {
      chooseName: false,
      errors: {},
    },
    drawerWidth: '15%',
  }),

  computed: {
    ...mapGetters('treeview', [
      'currentFolderVolumes',
      'currentFolderStudies',
      'currentFolder',
    ]),
    ...mapState('treeview', [
      'activeStudyId',
    ]),
    ...mapGetters('treeview/search', [
      'active',
    ]),
    ...mapState('treeview/search', [
      'results',
      'status',
    ]),
    ...mapGetters('auth', [
      'isAuthenticated',
    ]),
    ...mapState('ui', [
      'sidebar',
    ]),
    volumesToDisplay(): IVolume[] {
      return this.active ? this.results.volumes : this.currentFolderVolumes;
    },
    foldersToDisplay(): IFolder[] {
      if (this.active) {
        return this.results.folders;
      }

      if (this.currentFolder) {
        return this.currentFolder.folders;
      }

      return [];
    },
    studiesToDisplay(): IStudy[] {
      if (this.currentFolder) {
        return this.currentFolderStudies;
      }
      return [];
    },
    currentLocationChain(): IFolder[] {
      if (this.currentFolder) {
        const { id, name } = this.currentFolder;
        return [...this.currentFolder.parentChain, { id, name }];
      }

      return [];
    },
    folderNamesBlacklist(): string[] {
      return this.currentFolder.folders.map((folder: IFolder) => folder.name);
    },
  },

  async mounted() {
    this.$watch(() => this.searchQuery, debounce(this.updateSearch, 500));

    if (this.$vuetify.breakpoint.width < 1480) {
      this.drawerWidth = '20%';
    }

    // The ref should be defined
    const drawerEl = (this.$refs.navigationDrawer as Vue).$el as HTMLElement;
    const drawerBorder = drawerEl.querySelector(
      '.v-navigation-drawer__border',
    ) as HTMLElement;
    drawerBorder.style.width = '4px';
    drawerBorder.style.zIndex = '3';
  },

  methods: {
    ...mapActions('treeview', [
      'loadFolder',
      'createFolder',
    ]),
    ...mapActions('treeview/search', [
      'updateSearch',
    ]),
    ...mapMutations('treeview/search', {
      setSearchStatus: 'SET_STATUS',
    }),
    async handleFolderClicked(folderId: Uuid) {
      this.setSearchStatus('STALE');
      this.loadFolder({ folderId });
    },

    async handleCreateFolderSaveClicked({ name }: { name: string }) {
      if (!this.currentFolder) {
        throw new Error('No selected folder');
      }

      try {
        await this.createFolder({ parentFolderId: this.currentFolder.id, name });
        this.dialogs.chooseName = false;
      } catch (e) {
        catchError(e, [DetailedError]);
        this.dialogs.errors = (e as DetailedError).details;
      }
    },
  },
});
