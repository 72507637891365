/* eslint-disable max-classes-per-file */

export class DetailedError extends Error {
  details?: Record<string, string[]>

  constructor(details?: Record<string, string[]>, message?: string) {
    super(message);
    this.details = details;
  }
}

export class NotFoundError extends Error {}
export class UnauthorizedError extends Error {}
export class ConflictError extends DetailedError {}
export class ValidationError extends DetailedError {}
export class GoneError extends Error {}
export class ForbiddenError extends Error {}
export class TooManyRequestError extends Error {}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function catchError(error: unknown, errorClasses: any[]) {
  for (const errorClass of errorClasses) {
    if (error instanceof errorClass) {
      return;
    }
  }

  throw error;
}
