import {
  search,
  IFolder, IVolume,
} from '@/api/item';
import { Module } from 'vuex';
import { IRootState } from './types';

type SearchStatus = 'STALE' | 'IN_PROGRESS' | 'DONE'

interface ISearchStoreState {
  results: {
    volumes: IVolume[]
    folders: IFolder[]
  }
  status: SearchStatus
}

export default {
  state: {
    results: {
      volumes: [],
      folders: [],
    },
    status: 'STALE',
  },
  getters: {
    active: (state) => state.status !== 'STALE',
  },
  mutations: {
    SET_STATUS(state, status: SearchStatus) {
      state.status = status;

      if (status === 'STALE') {
        state.results.folders = [];
        state.results.volumes = [];
      }
    },
    SET_SEARCH_RESULTS(state, { volumes, folders }: { volumes: IVolume[], folders: IFolder[] }) {
      state.results.volumes = volumes;
      state.results.folders = folders;
    },
  },
  actions: {
    async updateSearch({ commit, rootGetters }, searchQuery: string) {
      if (searchQuery === '' || searchQuery == null) {
        commit('SET_STATUS', 'STALE');
        return;
      }

      commit('SET_STATUS', 'IN_PROGRESS');
      const { folders, volumes } = await search(rootGetters['treeview/currentFolder'].id, searchQuery);
      commit('SET_SEARCH_RESULTS', {
        folders,
        volumes,
      });
      commit('SET_STATUS', 'DONE');
    },
  },
  namespaced: true,
} as Module<ISearchStoreState, IRootState>;
