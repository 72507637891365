
import Vue from 'vue';
import MainWebApp from '@/components/MainWebApp.vue';
import NoAvailableSlotView from '@/components/NoAvailableSlotView.vue';
import { mapActions, mapMutations, mapState } from 'vuex';
import { catchError, ForbiddenError, UnauthorizedError } from '@/helpers/errors';
import { AuthorizationStatus } from '@/store/auth.store';
import { PRICING_REDIRECT_URI } from '../../auth_config';

export default Vue.extend({
  components: {
    MainWebApp,
    NoAvailableSlotView,
  },
  props: {},
  data() {
    return {
      AuthorizationStatus,
    };
  },
  computed: {
    ...mapState('auth', [
      'status',
    ]),
    isAuthenticated(): boolean {
      // @ts-expect-error auth
      return this.$auth.isAuthenticated;
    },
  },

  watch: {
    async isAuthenticated(isAuthenticated, old) {
      if (isAuthenticated && !old) {
        this.loadRootFolder();
      }
    },
  },

  async created() {
    this.setAccessType('authenticated');
    this.loadRootFolder();
  },

  methods: {
    async loadRootFolder() {
      // Authenticated access: try to load the user root folder
      try {
        // @ts-expect-error auth plugin
        if (this.$auth.isAuthenticated) {
          await this.loadFolder({ folderId: 'root' });
          this.setStatus(AuthorizationStatus.ACTIVE);
        }
      } catch (e) {
        catchError(e, [UnauthorizedError, ForbiddenError]);

        if (e instanceof UnauthorizedError) {
          // User is not logged in, redirect him to the login page
          this.setStatus(AuthorizationStatus.UNAUTHORIZED);
          // this.redirectToLogin();
        } else if (e instanceof ForbiddenError) {
          if (e.message === 'ERR_NO_AVAILABLE_SLOT') {
            this.setStatus(AuthorizationStatus.NO_AVAILABLE_SLOT);
          } else {
            // User is not active (e.g. no plan is currently active)
            this.setStatus(AuthorizationStatus.INACTIVE);
          }
        }
      }
    },
    ...mapActions('treeview', [
      'loadFolder',
    ]),
    ...mapMutations('auth', {
      setAccessType: 'SET_ACCESS_TYPE',
      setStatus: 'SET_STATUS',
    }),
    redirectToPricing() {
      const uri = `${PRICING_REDIRECT_URI}?from=${encodeURIComponent(window.location.origin)}`;
      window.location.assign(uri);
    },
    // Log the user in
    login() {
      // @ts-expect-error auth plugin
      this.$auth.loginWithRedirect();
    },
  },
});
