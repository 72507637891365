
import Vue from 'vue';
import { mapMutations, mapState } from 'vuex';
import UpgradePlanDialog from './components/dialogs/UpgradePlanDialog.vue';

export default Vue.extend({
  name: 'App',
  components: { UpgradePlanDialog },
  data: () => ({
    //
  }),
  computed: {
    ...mapState('ui', [
      'snackbar',
      'promptBeforeLeave',
      'upgradePlanDialog',
    ]),
  },
  created() {
    // The app can be in such a state that leaving it could put the user data in an incoherent state
    // e.g. during an upload, if the user closes the tab/browser or reload the page, the upload will be incomplete
    window.addEventListener('beforeunload', (e) => {
      if (this.promptBeforeLeave) {
        e.preventDefault();
        e.returnValue = '';
      }
    });
  },
  methods: {
    ...mapMutations('ui', {
      hideSnackbar: 'HIDE_SNACKBAR',
    }),
    visibilityChanged(visible: boolean) {
      if (!visible) {
        this.hideSnackbar();
      }
    },
  },
});
